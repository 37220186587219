import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en';
import he from './he';

//const lng = 'he';
const lng = process.env.NODE_ENV !== 'development' ? 'he' : 'en';

export default function init() {
  return i18next
    .use(initReactI18next)
    .init({
      lng: lng,
      fallbackLng: 'en',
      resources: {
        en,
        he,
      },
      debug: process.env.NODE_ENV !== 'production',
    })
    .then(() => {
      if (process.env.NODE_ENV === 'staging' && localStorage.getItem('translation')) {
        const trans = JSON.parse(localStorage.getItem('translation'));
        i18next.addResourceBundle(i18next.language, 'translation', trans.translation, true, true);
      }
    });
}
