/* eslint-disable */
const he = {
  translation: {
    admin: {
      capabilities: {
        create_questions: 'יצירת שאלות חדשות',
        delete_own_questions: 'מחיקת השאלות שלו בלבד',
        delete_questions: 'מחיקת שאלות (של כולם)',
        edit_own_questions: 'עריכת השאלות שלו בלבד',
        edit_questions: 'עריכת שאלות (של כולם)',
        manage_comments: 'ניהול הערות ציבוריות',
        manage_decks: 'ניהול חפיסות',
        manage_guides: 'ניהול סימלונים',
        manage_leeches: 'ניהול כ.עלוקה',
        manage_posts: 'ניהול חדשות',
        manage_question_topics: "ניהול 'נושאי' השאלות",
        manage_questions: 'ניהול שאלות (חובה לכותב)',
        manage_reports: 'ניהול דיווחים',
        manage_revlogs: 'ניהול Log',
        manage_simulations: 'ניהול סימולציות',
        manage_site: 'ניהול הגדרות האתר',
        manage_teams: 'ניהול קבוצות',
        manage_users: 'ניהול סטודנטים',
        view_questions: 'צפייה בשאלות (של כולם)',
      },
      cardComments: {
        filter: {
          all: 'הכל',
          card: "מס' כרטיס",
          comment: "מס' סידורי כרונולוגי",
          deck: 'נושא סילבוס',
          order: { label: 'סדר', options: { asc: 'עולה', desc: 'יורד' } },
          orderBy: {
            label: 'מיין ע"פ',
            options: {
              created_at: 'נוצר בתאריך',
              id: 'מספר סידורי',
              reputation: 'פופולאריות',
              updated_at: 'התעדכן בתאריך',
            },
          },
          subdeck: 'חפיסה',
          user: "מס' משתמש",
        },
        heading: 'הערות ציבוריות',
      },
      cards: {
        create: 'צור כרטיס חדש בחפיסה זו',
        form: { create: 'צור כרטיס!', node: 'סוג\\תכונות כרטיס (מתקדם)', update: 'שמור שינויים' },
        heading: 'כרטיסי החפיסה',
        search: { placeholder: 'חפש ...' },
      },
      convert: {
        almogToAnki: 'המר Almog ל- Anki',
        ankiToAlmog: 'המר Anki ל- Almog',
        heading: 'המרת קבצים',
        upload: 'העלאת קובץ',
      },
      decks: {
        create: 'צור נושא חדש לחפיסות שינון',
        form: {
          create: 'צור נושא חדש לחפיסות',
          decription: "תיאור של הנושא (ספר \\ מס' עמודים \\ נושאים וכו')",
          main: 'חלק מהסילבוס',
          priority: 'מיקום בסידור הנושאים',
          title: 'שם הנושא',
          update: 'שמור שינויים',
        },
        heading: 'חפיסות שינון',
        menu: { delete: 'מחק', edit: 'ערוך', subdecks: 'חפיסות שינון' },
        table: {
          actions: 'פעולות נוספות',
          cards: 'מספר הכרטיסים בנושא',
          created: 'נוצר בתאריך',
          main: 'חלק מהסילבוס',
          models: 'סוג כרטיס',
          priority: 'מיקום בסידור',
          subdecks: 'מספר חפיסות שינון בנושא זה',
          title: 'שם הנושא',
          updated: 'עודכן בתאריך',
        },
      },
      delete: {
        cancel: 'לא! מזל ששאלת.',
        confirm: 'כן אני בטוח, מחק.',
        text: '⛔האם אתה בטוח שתרצה למחוק דבר זה?⛔',
        title: 'אשר מחיקה!',
      },
      emails: {
        create: 'סגנן אימייל נודניק חדש',
        form: {
          body: 'גוף האימייל',
          create: 'צור נודניק! ',
          default: 'קבע אימייל זה כברירת המחדל',
          name: 'כותרת האימייל',
          subject: 'נושא האימייל',
          update: 'שמור שינויים',
          users: 'בחר סטודנטים נמענים למייל זה',
        },
        heading: 'מערכת התראות על אי סיום שינון יומי',
        table: {
          actions: 'פעולות נוספות',
          created_at: 'נוצר בתאריך',
          default: 'מוגדר כברירת מחדל',
          name: 'כותרת',
          updated_at: 'עודכן בתאריך',
          users: 'נשלח לסטודנטים',
        },
      },
      export: { heading: 'ייצוא חפיסות', name: 'צור שם לחפיסה ...', submit: 'ייצא חפיסות ' },
      general: { all: 'הכל', error: 'התרחשה בעיה...' },
      guides: {
        create: 'צור ערך חדש',
        form: {
          body: 'גוף התיאור',
          create: 'צור את הערך ',
          description: 'תיאור',
          icon: 'סימלון',
          priority: 'מיקום  ',
          public: 'מוצג לסטודנטים',
          title: 'שם',
          update: 'שמור שינויים',
        },
        heading: 'אנציקלופדיית סימלונים',
        table: {
          actions: 'פעולות נוספות',
          created_at: 'נוצר בתאריך',
          description: 'תיאור',
          icon: 'סימלון',
          priority: 'מיקום',
          public: 'מוצג לסטודנטים',
          title: 'שם',
          updated_at: 'עודכן בתאריך',
        },
      },
      import: {
        choose: 'בחר קובץ ( פורמט: .almog )',
        deck: 'בחר נושא ',
        decks: 'חפיסות',
        fields: 'שדות',
        heading: 'ייבוא כרטיסים',
        import: 'ייבוא',
        invalid: 'הקובץ אינו נתמך',
        media: 'מדיה',
        models: 'סוג כרטיס',
        next: 'הבא',
        node: 'סוג כרטיס מקביל בְּ ALMOGMETHOD',
        notes: 'הערות',
        replace: 'החלף (סמן ובחר את הסוג המקביל הרצוי)',
        start: 'התחל בייבוא הכרטיסים! 😍',
        steps: {
          data: 'בחר נתונים',
          deck: 'בחר את חפיסת השינון של הכרטיסים ',
          file: 'בחר קובץ לייבוא',
          import: 'ייבא את הכרטיסים',
          models: 'החלף סוג כרטיס לסוג מקביל של ALMOGMETHOD',
        },
        templates: 'תבניות',
      },
      leeches: {
        filter: { card: 'מספר סידורי של הכרטיס', user: 'user ID' },
        heading: 'כרטיסי "עלוקה"',
        table: {
          card_id: 'ID כרטיס',
          created_at: 'נוצר ב',
          id: 'ID אירוע',
          lapses: 'נענה כטעות',
          leeches: 'ספירת עלוקות החוזרות לכרטיס זה',
          resolved_at: 'נפתר ב',
          user: 'שם',
        },
      },
      media: {
        clean: 'נקה מדיה שלא בשימוש',
        deleted: 'נמחקו {{count}} קבצים בהצלחה!',
        heading: 'מדיה',
        table: {
          created_at: 'נוצר בתאריך',
          delete: 'מחק',
          filename: 'שם הקובץ',
          filesize: 'משקל',
          id: 'מספר סידורי',
          mime: 'Mime',
          type: 'נמצא בְּ',
          user: 'Uploader',
        },
        trash: 'זבל',
      },
      memberships: {
        active: 'פעיל (מנוי בתוקף)',
        expired: 'מנוי הסתיים ("סטודנט חוזר")',
        free: 'ללא הגבלה',
        newbie: 'חדש (ללא מנוי)',
        pending: 'בהמתנה לפתיחת הקורס',
      },
      nodes: {
        create: 'Create node',
        fields: {
          create: 'Create field',
          heading: 'Fields',
          label: 'Label',
          name: 'Name',
          priority: 'Priority',
          update: 'Save changes',
        },
        form: {
          create: 'Create node',
          delete: 'Delete',
          label: 'Label',
          name: 'Name',
          public: 'Public',
          type: 'Type',
          update: 'Save changes',
        },
        heading: 'Nodes (מתקדם)',
        menu: { delete: 'Delete', edit: 'Edit', fields: 'Fields', templates: 'Templates' },
        table: {
          actions: 'Actions',
          cards: 'Cards',
          fields: 'Fields',
          label: 'Label',
          models: 'Models',
          name: 'Name',
          templates: 'Templates',
          updated: 'Updated',
        },
        templates: {
          back: 'Back',
          create: 'Create template',
          front: 'Front',
          heading: 'Templates',
          name: 'Name',
          styles: 'Styles',
          update: 'Save changes',
        },
      },
      notifications: {
        create: {
          message: 'Message',
          send: 'Send',
          success: 'Notification sent!',
          title: 'Add notification',
          users: 'Users',
        },
      },
      orders: {
        heading: 'הרשמה ותשלומים',
        status: {
          completed: 'הושלם',
          created: 'נוצר',
          failed: 'נכשל',
          failedToCreate: 'נכשל ביצירה',
          failed_to_create: 'לא נוצר',
          pending: 'בהמתנה לתשלום',
        },
        table: {
          created_at: 'יצירה',
          id: 'ID',
          price: 'מחיר',
          status: 'סטטוס',
          updated_at: 'עדכון אחרון',
          user: 'משתמש',
          user_id: 'ID משתמש',
        },
      },
      posts: {
        create: 'צור הודעה לדף העדכונים',
        form: {
          body: 'גוף ההודעה',
          create: 'צור הודעה זו לדף העדכונים',
          description: 'תיאור',
          public: 'מוצג לסטודנטים',
          title: 'כותרת',
          update: 'שמור שינויים',
        },
        heading: 'חדשות ועדכונים',
        table: {
          actions: 'פעולות נוספות',
          created_at: 'נוצרה בתאריך',
          description: 'תיאור',
          public: 'מוצג לסטודנטים',
          published_at: 'פורסמה בתאריך',
          title: 'כותרת',
          updated_at: 'עודכנה בתאריך',
        },
      },
      questions: {
        added: '✅ שאלתך נוצרה בהצלחה ונוספה למאגר השאלות! תרומתך למדע לא תסולא בפז 🧠🩺',
        assign: {
          no_change: 'אל תשנה',
          selection: 'בחירה',
          submit: 'החל',
          tag_mode: {
            label: 'מצב תגיות',
            options: {
              add: 'הוסף תגיות נבחרות לקיימות',
              remove: 'הסר רק את התגיות הנבחרות',
              sync: 'החלף תגיות קיימות בתגיות נבחרות',
            },
          },
        },
        filter: {
          add_all: 'הוסף את כולן',
          all: 'הצג מהכל',
          author: 'יוצר השאלה',
          chapter: 'פרק',
          difficulty: 'קושי',
          filter: 'סינון',
          not_selected: 'אינן חלק מהסימולציה',
          not_used: 'פנויות לשימוש',
          remove_all: 'הסר את כולן',
          selected: 'מרכיבות את הסימולציה',
          simulation: 'סימולציות',
          sortBy: { label: 'מיין ע"פ', options: { created_at: 'נוצר בתאריך', id: 'ID', updated_at: 'עודכן בתאריך' } },
          tags: 'תגיות',
          topic: 'נושא',
          type: 'סיווג',
          used: 'נמצאות כעת בסימולציות',
        },
        form: {
          chapter: 'פרק',
          create: 'צור שאלה',
          difficulty: 'סיווג קושי',
          explanation: 'הסבר ALMOGMETHOD ורפרנס:',
          heading: 'מערכת יצירת וסיווג שאלות',
          lock: 'נעל את השדות המסומנים',
          option_1: 'א. ',
          option_2: 'ב. ',
          option_3: 'ג. ',
          option_4: 'ד. ',
          question: 'שאלות',
          reset: 'אפס שדות',
          tags: 'צור\\בחר תגיות',
          topic: 'נושא',
          type: 'סיווג',
          update: 'שמור שינויים',
        },
        heading: 'מערכת שאלות',
        tabs: { assign: 'ניהול פרטי השאלות', filter: 'בורר השאלות' },
        types: { all: 'כלל השאלת', practice: 'תרגול', simu: 'סימולציות' },
      },
      reports: {
        delete_resolved: 'מחק הכל',
        filter: {
          all: 'הכל',
          author: 'יוצר ',
          resolved: { label: 'טופל', options: { no: 'לא', yes: 'כן' } },
          type: { label: 'סוג', options: { card: 'כרטיס', card_comment: 'הערה ציבורית', question: 'שאלה' } },
        },
        heading: 'דיווחי סטודנטים',
        menu: { delete: 'מחק דיווח', message: 'תוכן הדיווח', resolve: 'טופל', view: 'קח אותי לכרטיס\\שאלה' },
        table: {
          actions: 'פעולות נוספות',
          author_name: 'יוצר',
          created_at: 'תאריך דיווח',
          email: 'אימייל',
          item_id: 'מספר סידורי',
          item_type: 'מדווח על',
          name: 'שם וְ- שם משפחה',
          resolved_at: 'טופל בתאריך',
          user_id: 'User ID',
        },
        types: { card: 'כרטיס', card_comment: 'הערה ציבורית', question: 'שאלה' },
      },
      reset: {
        cancel: 'ביטול.',
        confirm: 'כן! בצע אתחול',
        text: '⛔אתה בטוח שתרצה לאתחל דבר זה?⛔',
        title: 'אשר איתחול!',
      },
      revlogs: {
        filter: { card: "מס' הכרטיס", user: "מס' משתמש" },
        heading: 'רישום הצגת כרטיסים לסטודנט',
        table: {
          button: 'דורג כּ',
          card_id: 'ID כרטיס',
          created: 'נוצר בּתאריך',
          duration: 'משך זמן (sec)',
          id: "מס' סידורי",
          next_due: 'מְתוזמן לתאריך',
          user_id: 'ID משתמש',
          version: 'גרסת האתר',
          was_due: 'תוזמן לתאריך',
        },
      },
      roles: { admin: '👑Admin👑', all: 'סה"כ', staff: 'צוות', teacher: "קואצ'ר וכותבי שאלות", user: 'סטודנט' },
      settings: {
        arena: {
          alert: {
            cancel: 'ביטול',
            confirm: 'זוכר. תאפס',
            text: 'מזכיר לך אלמוג בעתיד! שאיפוס זה יימחק את נקודות כלל משתמשי האתר ואת התקדמותם. ',
            title: 'אשר איפוס כולל של מערכת "לומדים יחד!"',
          },
          heading: 'איפוס כולל של מערכת "לומדים יחד!"',
          reset: 'אֳפּס את כל נקודות האתר ',
        },
        course: { endDate: "תאריך סיום (3 שבועות לאחר מועד ב')", heading: 'קורס נוכחי', startDate: 'תאריך התחלה' },
        goal: {
          cardsAmount: 'החל על כמות כרטיסים מותאמת אישית  (לא מומלץ)',
          countCards: 'החל אוטומטית ע"פ כמות הכרטיסים של האתר (מומלץ)',
          endDate: 'תאריך סיום הקורס (ברירת מחדל)',
          heading: 'יעדי למידה (ברירת מחדל)',
          simulations: 'סימולציות (ברירת מחדל)',
          startDate: 'תאריך התחלת הקורס (ברירת מחדל)',
        },
        heading: 'הגדרות לימוד ושינון יומי',
        learn: {
          general: {
            collapseTime:
              'Collapse time (כמות התיזמון בשניות, שניתן לקצר ולהציג מידיית כרטיס, אם אין עוד כרטיסים לפניו. 0 = תחכה הכל כרגיל. 1200 = ב. מחדל)',
            dailySkips: 'מקסימום דילוגים מעל כרטיסים',
            heading: 'כללי',
            maxFactor: 'פקטור מקסימלי',
            newLimit: 'מקסימום כרטיסים חדשים (מעבר למספר זה לא ניתן להוסיף עוד חפיסות)',
            queueLimit:
              'מקסימום הכרטיסים המורשים "לעמוד בתור" ולחכות (לא יוצגו כרטיסים חדשים עד לקיצור התור למספר זה. Queue limit) ',
          },
          lapse: {
            delays: 'שלבי למידה לכרטיס שלא שונן בזמן (Relearning steps)',
            heading: 'בעת דילוג מעל כרטיס ואי שינון מתוזמן שלו (Lapses)',
            leeches: 'כרטיס "עלוקה" (.. steps, repeat)',
            minIvl: 'מינימום הימים שכרטיס שלא שונן יחכה לפני הופעתו (Minimum Interval ברירת מחדל יום אחד)',
            mult: 'יצירת מרווח-זמן חדש לכרטיס שלא שונן (New interval ברירת מחדל 0. כלומר יתחיל מההתחלה, אך ה Ease factor יפצה על כך)',
          },
          new: {
            bury: 'טמון כרטיסים "אחים" (צדדים שונים של אותו הכרטיס וכו\') עד למחרת',
            delays: 'שלבי למידה (Learning steps)',
            easyIvl: 'מרווח זמן של "ידעתי הכל" (קל)',
            goodIvl: 'מרווח זמן של "סביר" (טוב)',
            heading: 'כרטיסים חדשים',
            initialFactor: 'פקטור קֳלוּת התחלתית (Initial factor)',
            spread: {
              distribute: 'ערבב חדשים וחזרות מתוזמנות',
              first: 'הצג ראשונים',
              label: 'סדר הצגת הכרטיסים החדשים',
              last: 'הצג אחרונים',
            },
          },
          rev: {
            bury: 'טמון כרטיסים "אחים" עד למחרת (Bury sibiling)',
            easyFactor: 'בונוס קֳלוּת (Easy bonus)',
            hardFactor: 'פקטור קושי (Hard factor)',
            heading: 'הצגת כרטיסים ללמידה (Review)',
            ivlFactor:
              'פקטור להתאמת האינטרוולים המובנים. 1 = ללא שינוי. 0.8 = 80% Interval * Interval Modifier = New Interval (Interval modifier) ',
            maxIvl:
              'מקסימום הימים שכרטיס יכול להיות לא מוצג בהם (Max interval המלצה: 21 לקראת המרתון 19. ולפני המבחן? לשנות כמספר הימים למבחן)',
          },
        },
        pdeck: { heading: 'חפיסות שינון אישיות', maxCards: 'מקסימום הכרטיסים האישיים פר סטודנט' },
        register: {
          heading: "הגבלת מס' הסטודנטים בקורס (נעילת אופציית ההרשמה)",
          maxUsers: 'מספר הסטודנטים המירבי בקורס',
        },
        reset: 'אפס שינויים לברירת המחדל',
        save: 'שמור שינויים',
        saved: '!השינויים נשמרו בהצלחה',
        shop: {
          heading: 'מוצרים',
          price: 'מחיר רגיל',
          productName: 'שם מוצר',
          renewPrice: 'מחיר הנחה (30%)',
          renewProductName: 'Renew product name',
        },
        simu: { difficulties: 'רמות קושי', heading: 'הגדרות סימולציה', practiceLimit: "מקס' סימולציות אישיות" },
        training: {
          enabled: 'ON',
          heading: 'שינון ידני',
          onlyAfterStudy: 'רק לאחר סיום שינון יומי מתוזמן',
          onlyFullDecks: 'רק חפיסות מלאות',
        },
      },
      sidebar: {
        cardComments: 'הערות ציבוריות',
        convert: 'המרת קבצים',
        dashboard: 'מרכז הפעולות שלי',
        decks: 'חפיסות שינון',
        emails: 'מערכת נודניק למידה',
        export: 'ייצוא חפיסות וגיבוי',
        guides: 'אנציקלופדיית סימלונים',
        import: 'ייבוא כרטיסים לאתר',
        leeches: 'כרטיסי "עלוקה"',
        media: 'אחסון תמונות',
        nodes: 'סוגי כרטיסים (מתקדם)',
        orders: 'הרשמה תשלומים',
        posts: 'חדשות ועדכונים',
        questions: 'יצירת שאלות ',
        reports: 'דיווחי סטודנטים',
        revlogs: 'מעקב הצגת כרטיסים',
        settings: 'הגדרות ',
        simulations: 'סימולציות',
        statistic: '????',
        teams: 'קבוצות "לומדים יחד!"',
        translate: 'מערכת תרגום',
        users: 'הסטודנטים שלי',
      },
      simufolders: {
        create: 'צור תיקייה חדשה',
        form: {
          color: 'צבע',
          colors: {
            deck1: 'ביוכימיה (ירוק)',
            deck2: 'מולקולרית (ורוד)',
            deck3: 'ביותא (צהוב)',
            deck4: 'פיזיולוגיה (כחול)',
            default: 'ברירת מחדל',
          },
          description: 'תיאור',
          icon: 'סמל אייקוני',
          no_parent: 'ללא תיקיית אם',
          parent: 'תיקיית אם',
          priority: 'קדימות',
          save: 'צור סימולציה',
          title: 'כותרת',
          update: 'שמור שינויים',
        },
        heading: 'תקיות',
      },
      simulation: {
        allTopic: 'כל הנושאים',
        create: 'צור שאלה חדשה',
        heading: 'סימולציה',
        question: {
          create: 'צור שאלה',
          explanation: 'הסבר ALMOGMETHOD & רפרנס:',
          option_1: 'א.',
          option_2: 'ב.',
          option_3: 'ג.',
          option_4: 'ד.',
          question: 'שאלה',
          topic: 'נושא',
          update: 'שמור שינויים',
        },
      },
      simulations: {
        create: 'צור סימולציה ראשונית',
        form: {
          color: 'צבע',
          colors: {
            deck1: 'ביוכימיה (ירוק)',
            deck2: 'מולקולרית (ורוד)',
            deck3: 'ביותא (צהוב)',
            deck4: 'פיזיולוגיה (כחול)',
            default: 'ברירת מחדל',
          },
          create: 'צור סימולציה ',
          description: 'תיאור סימולציה',
          folder: 'תיקיית-אם',
          icon: "אימוג'י אייקוני",
          no_folder: 'לא נמצא בתיקייה',
          priority: 'קדימות במיקום',
          status: 'סטטוס בקורס',
          submit_each_question: 'מצב שאלות תרגול (שאלה-תשובה)',
          title: 'שם הסימולציה',
          update: 'שמור שינויים',
        },
        heading: 'מערכת יצירת וארגון סימולציות',
        menu: {
          delete: 'מחק סימולציה',
          edit: 'ערוך פרטי סימולציה',
          questions: 'צפה בפירוט השאלות',
          results: 'ציוני סטודנטים',
          topics: 'Topics',
        },
        shuffle: {
          global: 'צור ערבוב קבוע מראש',
          options: 'ערבב את סידור המסיחים (לא מומלץ "א+ג נכונות" ייהרס)',
          questions: 'ערבב את סידור השאלות (אפשרי)',
          topics: 'ערבב בין הנושאים (לא מומלץ)',
        },
        status: { archived: 'מאוחסנת בארכיון', draft: 'בתהלכי בדיקה והגהה', published: 'מפורסמת לסטודנטים' },
        table: {
          actions: 'פעולות נוספות',
          created: 'נוצרה בתאריך',
          difficulty: 'סיווג קושי',
          folder: 'תיקיית-אם',
          priority: 'קדימות במיקום',
          questions: 'כמות שאלות',
          score: 'דירוג (משוקלל קושי)',
          status: 'סטטוס בקורס',
          title: 'שם הסימולציה',
          topics: 'Topics',
          updated: 'עודכנה בתאריך',
        },
      },
      simuresults: {
        button: 'ממוצע הסטודנט\\ים ',
        drawer: { result: 'ציונים', summary: 'השגי הסטודנט\\ים ', topics: 'נושאים' },
        heading: 'תוצאות הסטודנט\\ים ',
        table: {
          actions: 'פעולות נוספות',
          correct: 'נענו נכון',
          created_at: 'תאריך ושעת ביצוע',
          duration: 'משך הזמן',
          minutes: '{{minutes}} דקות',
          name: 'שם',
          questions: 'שאלות שנענו',
          result: 'ציון',
          score: 'ניקוד (משוקלל קושי)',
          simulation: 'סימולציה',
          wrong: 'נענו שגוי',
        },
      },
      subdecks: {
        create: 'צור חפיסת שינון חדשה',
        form: {
          create: 'צור חפיסת שינון',
          deck: 'נמצא בתוך נושא',
          decription: "תיאור החפיסה (נושאים \\ עמודי רלוונטיים \\ פרק וכו')",
          priority: 'בחר את מיקום החפיסה בנושא',
          public: 'זמין לסטודנטים',
          title: 'שם החפיסה',
          update: 'שמור שינויים',
        },
        heading: 'חפיסות שינון',
        menu: { cards: 'צפה בכרטיסי החפיסה', delete: 'מחק חפיסה', edit: 'שנה הגדרות' },
        table: {
          actions: 'פעולות נוספות',
          cards: 'כמות הכרטיסים',
          created: 'נוצר בתאריך',
          models: 'סוג כרטיסים',
          priority: 'מיקום חפיסה בתוך הנושא',
          public: 'זמין לסטודנטים',
          title: 'שם החפיסה',
          updated: 'עודכן בתאריך',
        },
      },
      table: { displayedRows: '{{from}}-{{to}} of {{count}}', rowsPerPage: 'שורות לדף' },
      teams: {
        heading: 'קבוצות "לומדים יחד"',
        reset: 'אתחל קבוצה',
        table: {
          actions: 'פעולות',
          admin: 'הגדרות הקבוצה',
          created_at: 'נוצרה בתאריך',
          id: 'מספר סידורי',
          image: 'תמונת קבוצה',
          leader: 'יוצר הקבוצה',
          members: "מס' חברי הקבוצה",
          name: 'שם הקבוצה',
          points: 'נקודות קבוצתיות',
          rank: 'דירוג הקבוצה',
        },
      },
      topics: {
        create: 'צור נושא חדש (מתקדם)',
        form: {
          color: 'צבע נושא',
          name: 'שם הנושא',
          priority: 'קדימות בסידור',
          save: 'צור נושא!',
          update: 'עדכן שינויים',
        },
        heading: 'נושאים',
      },
      translate: { download: 'הורדת התרגום', heading: 'תרגם', reset: 'איפוס', rewrite: 'קשר תרגום לאתר' },
      users: {
        create: 'צור סטודנט',
        decks: {
          none: 'אין חפיסות',
          subdeck_info: 'נוסף בתאריך: {{added_at}} | התקדמות: {{progress}}/{{cards}}',
          title: 'חפיסות קיימות',
        },
        form: {
          capabilities: { groups: { general: 'הרשאות כלליות', questions: 'הרשאות לכותבי שאלות' }, label: 'הרשאות' },
          create: 'צור סטודנט',
          delete: 'מחק סטודנט',
          email: 'אימייל',
          email_verified: 'אימייל מאומת',
          enabled: 'סימולציות מאושרות ',
          mark_weekend: 'סמן תזמון שישי-שבת (דתיים)',
          membership: {
            label: 'סוג מנוי',
            options: {
              active: 'פעיל (מנוי בתוקף)',
              expired: 'מנוי הסתיים ("סטודנט חוזר")',
              free: 'חופשי ללא הגבלה',
              newbie: 'חדש (ללא מנוי)',
              pending: 'בהמתנה לפתיחת הקורס',
            },
          },
          name: 'שם',
          notes: 'הערת אדמין',
          password: 'סיסמה',
          phone: 'נייד',
          points: 'נקודות אישיות',
          role: 'תפקיד',
          simulations: 'אישור צפייה בסימולציות',
          team_points: 'נקודות קבוצתיות',
          update: 'שמור שינויים',
          weekend: {
            label: 'הגדרות מיוחדות לשומרי שבת:',
            skip_friday: 'אל תתזמן לשישי',
            skip_saturday: 'אל תתזמן לשבת',
          },
        },
        heading: 'סטודנטים',
        menu: {
          decks: 'חפיסות',
          delete: 'מחק סטודנט (לא הפיך)',
          edit: 'ערוך פרטי הסטודנט',
          impersonate: 'היכנס למשתמש',
          points: 'נקודות',
          progress: 'ייעדי למידה אישיים',
          simulations: 'סימולציות הסטודנט',
          stats: "סטטי' הסטודנט",
        },
        points: { add: 'הוספה', amount: 'כמות', log: 'היסטוריית נקודות', note: 'מדוע נוסף?', submit: 'אישור' },
        search: { placeholder: 'חיפוש משתמש ...' },
        table: {
          actions: 'פעולות',
          anonym: 'אנונימי',
          authored_questions_count: 'שאלות (צוות)',
          can_simulate: 'גישה לסימולציות',
          cards: 'כרטיסיות אישיות',
          created_at: 'נוצר בתאריך',
          email: 'אימייל',
          email_verified_at: 'אימות אימייל',
          fake_name: 'שם אנונימי',
          id: 'ID',
          image: 'תמונה',
          is_online: 'Online',
          last_active_at: 'פעולה אחרונה',
          last_card_at: 'כרטיס אחרון',
          learns_count: 'חפיסות בשינון',
          membership: 'מנוי',
          name: 'שם ',
          notes: 'הערה',
          phone: 'נייד',
          points: 'נק. אישיות',
          practice_simulations_count: 'סימולציות תרגול',
          role: 'תפקיד ',
          simulation_results_count: 'סימולציות מבחן',
          simulations: 'מאושר לצפייה בסימולציות',
          simulations_count: 'סימולציות שבוצעו',
          team: 'קבוצה',
          team_points: 'נק. קבוצתיות',
        },
      },
    },
    arena: {
      alone: {
        create: 'צור לי קבוצה חדשה',
        join: 'הצטרפות לקבוצה קיימת',
        prompt: {
          button: 'צור קבוצה',
          cancel: 'ביטול',
          label: 'שם קבוצתי',
          text: 'רשמו כאן את שם הקבוצה, לשם מקורי יש השפעה חיובית על יכולות הקבוצה ודירוגה ...',
          title: 'צור את קבוצת חלומותייך כאן!',
        },
        text: 'למידה חברותית הוּכחה כיעילה יותר! ניתן ליצור קבוצה חדשה בעצמכם, או להצטרף לקבוצה קיימת על-ידי לחיצה על קבוצה בטבלת הקבוצות ושליחת בקשת הצטרפות!',
        title: 'טובים השניים, והשלושה? גאוני תבל!',
      },
      board: {
        members: { points: 'נקודות התמדה והשקעה', team_points: 'נקודות קבוצתיות' },
        people: {
          alerts: {
            blocked: 'לא תמיד זה נועד לקרות, קבוצתך נחסמה על-ידי המשתמש',
            denied: 'הגישה לא אושרה',
            exists: 'ממתין לאישור\\דחיית הזמנתך',
            not_free: 'המשתמש אינו פנוי',
            sent: 'הזמנתך נשלחה בהצלחה',
          },
          badges: 'הישגי התמדה והשקעה שברשותי:  ',
          form: {
            cancel: 'ביטול',
            label: 'הודעה',
            placeholder: 'הזמנה להצטרף אל קבוצתך',
            required: 'כתוב כאן מדוע כדאי להצטרף לקבוצתך...',
            send: 'שלח הזמנה',
          },
          info: {
            blocked: 'לא תמיד זה נועד לקרות, לצערנו המשתמש חסם את הזמנותיך',
            invited: 'היי! כיוונת לדעת גדולים, כבר נשלחה בקשת הצטרפות על ידי חברי קבוצתך',
            requested: 'היי! זיווג משמים, כבר נשלחה לך בקשת הצטרפות על ידי',
          },
          invite: 'הזמן לקבוצתי',
          unblock: 'הסר חסימה',
        },
        tabs: { members: 'תוך-קבוצתי', people: 'יחידים', teams: 'קבוצות' },
        teams: {
          alerts: {
            blocked: 'לא תמיד זה נועד לקרות, לצערנו נחסמת ע"י הקבוצה ',
            denied: 'הגישה נדחתה',
            exists: 'ממתין לאישור\\דחיית בקשת הצטרפותך',
            not_free: 'יש ממך רק אחד, והאחד הזה כבר חבר בקבוצה!',
            sent: 'בקשה נשלחה',
          },
          form: {
            cancel: 'ביטול',
            label: 'הודעה',
            placeholder: 'בקשת הצטרפות לקבוצה',
            required: 'כתוב כאן למנהל הקבוצה מדוע כדאי לצרפך לקבוצה ...',
            send: 'שלח בקשת הצטרפות',
          },
          info: {
            blocked: 'לא תמיד זה נועד לקרות, לצערנו נחסמת ע"י הקבוצה',
            invited: 'זיווג משמים, קבוצה זו כבר שלחה לך בקשת הצטרפות אליה!',
            requested: 'ממתין לאישור\\דחיית בקשת הצטרפותך',
          },
          request: 'בקשה להצטרפות',
          unblock: 'הסרת חסימה',
        },
      },
      celebrate: {
        button: 'מושלם!',
        text: 'התקדמת בלמידתך ועכשיו ההישג "{{badge}}" שייך לך! אני גאה בך!',
        title: 'ברכותיי!!',
      },
      heading: 'לומדים יחד!',
      invites: { accept: 'אשר', block: 'חסימת קבוצה', decline: 'סרב' },
      logs: {
        added_decks_x: 'על שהוספת {{level}} חפיסות שינון חדשות',
        avg_decks: "על יותר (5 נק') או כפול (5+2) חפיסות מהממוצע",
        daily_cards_x: 'על {{level}} כרטיסים מתוזמנים ששוננו על-ידך ביום החולף',
        daily_study: 'על שהשלמת השינון המתוזמן היומי שלך',
        empty: 'בבקשה אל תשאירו אותי ריק ! השקעה והתמדה הן הסוד להצלחה ...',
        finished_course: 'על שהשלמת את לימוד כל הקורס',
        finished_topic: 'על שהשלמת לימוד נושא שלם',
        monthly_study: 'על שהשלמת "לימוד מתוזמן יומי במשך 30 ימים"',
        monthly_study_x: 'על {{level}} פעמים שהשלמת את "הלימוד המתוזמן היומי במשך 30 ימים" ',
        more: 'לחצ\\י להמשך קריאה ...',
        number_one: 'על דירוגך במקום הראשון בטבלה ההתמדה והעשייה',
        study_11_hours: 'על למידה יומית של לפחות 11 שעות',
        study_11_hours_x: 'על שלמדת לפחות 11 שעות {{level}} פעמים',
        study_12_hours: 'על למידה יומית של לפחות 12 שעות',
        study_12_hours_x: 'על שלמדת לפחות 12 שעות {{level}} פעמים',
        study_9_hours: 'על למידה יומית של לפחות 9 שעות',
        study_9_hours_x: 'על שלמדת לפחות 9 שעות {{level}} פעמים ',
        study_time_x: 'על שלמדת כּ- {{hours}} שעות היום',
        top_five: 'על דירוגך בחמשת המקומות הראשונים בטבלה ההתמדה והעשייה',
        top_three: 'על דירוגך בשלושת המקומות הראשונים בטבלה ההתמדה והעשייה',
        weekly_streak_x: 'על שביצעת {{level}} פעמים "השלמת לימוד מתוזמן שבועי"',
        weekly_study: 'על שהשלמת "לימוד מתוזמן היומי" במשך 7 ימים ',
        weekly_study_x: 'על {{level}} פעמים שהשלמת את "הלימוד המתוזמן היומי במשך 7 ימים"  ',
      },
      messages: {
        deleted: 'הודעה נמחקה...',
        denied: 'הגישה נמחקה',
        empty: {
          text: 'זה הזמן לשלוח משפט מוטיבציה לחברי הקבוצה, להתעניין בשלומם או סתם להשוויץ בכמות הנקודות שלכם... ',
          title: 'כל כך שקט פה שניתן לשמוע קרפדות אילמות בהודו 😶',
        },
        pull: 'משוך למטה לביצוע רענון לדף',
        release: 'שחרר לביצוע רענון לדף',
      },
      nav: {
        invites: 'הזמנות',
        leaderboard: 'טבלת התמדה והשקעה',
        points: 'היסטוריית נקודות',
        profile: 'פרופיל',
        team: 'קבוצה',
      },
      profile: {
        anonym_off: 'אנונימיות כבויה',
        anonym_on: 'אנונימיות פעילה',
        badges: 'ההישגים שלי',
        level: 'רמה {{level}}',
        points: 'נקודות התמדה',
        team_points: 'נקודות התמדה קבוצתיות',
      },
      settings: {
        disband: {
          button: 'כן. פרק קבוצה!',
          heading: 'פירוק קבוצה',
          prompt: {
            cancel: 'ביטול',
            confirm: 'פרק קבוצה',
            text: 'מנהלים יקרים, אתם עומדים לפרק את קבוצתכם, בבקשה קחו עוד רגע לשקול פעולה זו בשנית...',
            title: 'פירוק קבוצה',
          },
          text: 'מנהלים יקרים, האם אתם בטוחים שברצונכם לפרק את הקבוצה הנפלאה שלכם, למחוק ולהשמיד את כל הישגיה ולהעלימה לעד מן העולם? 😞',
        },
        invites: { delete: 'מחק', heading: 'הזמנות הצטרפות לקבוצתך ששלחת' },
        members: {
          heading: 'חברי קבוצתי',
          prompt: {
            cancel: 'ביטול',
            confirm: 'כן ושיקוליי עימי',
            text: 'מנהלים יקרים, האם אתם בטוחים שתרצו להעיף מקבוצתכם קולגה לעתיד, ולשולחם לבדידות שממה וכיליון אינסופיים?',
            title: 'הינך עומד להדיח את חבר קבוצתך',
          },
        },
        poster: {
          allowed: 'ניתן להעלות תמונה רק בפורמטים הבאים: jpeg, jpg, png, gif',
          cancel: 'ביטול',
          change: 'שינוי תמונת קבוצה',
          confirm: 'אישור',
          delete: 'מחיקת תמונה ',
          description: 'ניתן לגרור את התמונה על-מנת לחותכה באופן הרצוי',
          max: 'המשקל המרבי: 5 MB',
          notimg: 'הקובץ שנבחר אינו נתמך, אנא בחר קובץ בפורמטים הבאים בלבד: .jpeg, .png or .svg file.',
          title: 'שינוי תמונת קבוצה',
          toobig: 'המשקל הזה כבד עלינו. אנא בחרו קובץ עד 5MB.',
          upload: 'העלאת תמונה',
          zoom: 'Zoom',
        },
        requests: { accept: 'אשר', block: 'חסום משתמש\\ת', decline: 'דחה ', heading: 'בקשות הצטרפות לקבוצתך' },
        reset: {
          button: 'אפס נקודות תוך-קבוצתיות',
          heading: 'איפוס נקודות תוך-קבוצתיות',
          prompt: {
            cancel: 'ביטול',
            confirm: 'אפס נקודות',
            text: 'הנכם עומדים לאפס את הנקודות האישיות התו-קבוצתיות שלכם (איפוס זה אינו משפיע על נקודות הקבוצה וכן לא על הנקודות האישיות מחוץ לקבוצה)',
            title: 'איפוס נקודות אישיות תוך-קבוצתיות',
          },
          text: 'איפוס נקודות אישיות תוך-קבוצתיות מאפשר לכלל חברי הקבוצה להתחיל להתחרות בניהם מאותו קו ההתחלה, איפוס זה אינו משפיע על הנקודות האישיות של חברי הקבוצה מחוץ לקבוצה, וכן לא על נקודות הקבוצה עצמה',
        },
        team: { heading: 'הגדרות קבוצה', name: 'שם הקבוצה', update: 'עדכן נתונים' },
      },
      team: {
        created: 'נוסדה בתאריך:',
        details: 'פרטי הקבוצה',
        leave: 'בטל את חברותי בקבוצה',
        leaving: {
          cancel: 'לא. השאר אותי בקבוצה',
          confirm: 'כן. שלום ולהתראות',
          text: 'עזיבת הקבוצה תמחק את כלל התקדמותך בה, ותשנה את ניקוד ודירוג הקבוצה. האם אתם בטוחים שתרצו לעזוב?',
          title: 'האם אתם בטוחים?',
        },
        members: "מס' חברים:",
        messages: 'הצג הודעות ועדכונים',
        points: "מס' נקודות:",
        settings: 'הצג הגדרות ובקשות',
        team_rank: 'דירוג קבוצה:',
      },
    },
    auth: {
      email: 'אימייל',
      error: 'משהו לא עבד כצפוי, אנא נסו שנית מאוחר יותר או צרו עמנו קשר ונפעל לפתור עבורכם את הבעיה ...',
      forgot: {
        back: 'חזרה לכניסה אל האתר',
        button: 'שלח לי אימייל איפוס סיסמה',
        heading: 'אפס סיסמה',
        label: 'כתובת האימייל שלך',
        sent: {
          button: 'חזרה לכניסה אל האתר',
          text: 'שלחנו לינק לכתובת {{email}}, עקוב אחרי ההנחיות בו לביצוע איפוס סיסמה',
          title: 'בדוק את תיבת ההודעות הנכנסות באימייל שלך',
        },
      },
      login: {
        button: 'התחבר כאן',
        forgotPw: 'שכחת סיסמה?',
        heading: 'ברוך שובך!',
        incorrect: 'סיסמה ו\\או מייל שגויים',
        signUpHere: 'הרשם כאן',
      },
      password: 'סיסמה',
      register: {
        agreeTerms: 'בהירשמות לאתר אני מסכימ\\ה עם הנהלים ומדיניות הפרטיות של ALMOGMETHOD',
        button: 'הרשמה',
        denied: {
          button: 'צרו עמנו קשר',
          text: 'נפתחה ההרשמה לקורס ההכנה לשנת 2025 לרישום נא לפנות לאלמוג במספר 054-3083435. תודה על התעניינותכם בפרויקט ואנו מאחלים לכם בהצלחה רבה וקבלה לרפואה. צוות ALMOGMETHOD',
          title: 'הרשמה לקורס',
        },
        heading: "It's a beautiful day to start learning...",
        name: 'שם פרטי וְ- שם משפחה',
        passwordConfirmation: 'חזור על הסיסמה שנית',
        passwordConfirmationError: 'הסיסמאות חייבות להיות תואמות',
        success: {
          button: 'התחברות למערכת',
          text: 'כעת נבצע התחברות ראשונית לאתר שם נוכל לרכוש מנוי שימוש באתר',
          title: 'נרשמת בהצלחה לאתר הפרויקט ALMOGMETHOD',
        },
      },
      reset: {
        button: 'עדכן סיסמה חדשה',
        expired: {
          button: 'קבל לינק חדש',
          text: 'מצטערים, אך לינק לאיפוס סיסמה זה פג-תוקף, תוכלו לבקש לינק חדש נוסף ולנסות דרכו שוב ...',
          title: 'הלינק לאיפוס סיסמה פג-תוקף',
        },
        heading: 'צור סיסמה',
        help: 'על הסיסמה להכיל לפחות 6 תווים',
        password: 'סיסמה חדשה',
        passwordAgain: 'חזור על הסיסמה החדשה',
        passwordDoesntMatch: 'מצטערים אך הסיסמאות אינן תואמות',
        saved: { button: 'כניסה', text: 'מיד תופנו באופן אוטומטי לכניסה אל האתר', title: 'סיסמה חדשה נשמרה!' },
      },
      verify: {
        expired: {
          button: 'התחברות',
          text: 'מצטערים, תוקפו של לינק זה פג. תוכלו לבקש ולקבל לינק חדש לאימות',
          title: 'פג תוקף הלינק',
        },
        not: {
          button: 'שלח אימייל אימות נוסף',
          text: 'אנא בדקו את האימייל שלכם ובצעו אימות לחשבון',
          title: 'לא בוצע אימות לכתובת האימייל שלכם',
        },
        sent: {
          error: 'התרחשה שגיאה בשליחת האימייל, אנא נסו שוב מאוחר יותר',
          note: 'שימו לב: אם אינכם מוצאים את האימייל בתיבת ההודעות הנכנסות, אנא בדקות את תיבת הספאם שלכם!',
          text: 'שלחנו לכם אימייל אימות חשבון ל {{email}}.אנא בדקו את תיבת ההודעות הנכנסות שלכם ובצעו אימות לחשבונכם',
          title: 'נשלח אליכם אימייל לאימות חשבון',
        },
        verified: {
          button: 'התחברות',
          text: 'הצלחנו לאמת את האימייל שלך, ניתן כעת להיכנס למערכת',
          title: 'אימות האימייל בוצע בהצלחה',
        },
      },
    },
    badges: {
      added_decks_x15: {
        desc: 'הוספת עד כה 15 חפיסות חדשות לרוטינת השינון היומי שלך',
        name: 'הוספת 15 חפיסות שינון',
        tip: 'על הוספה של 15 חפיסות שינון כלשהן',
      },
      added_decks_x40: {
        desc: 'וואו! הוספת עד כה 40 חפיסות חדשות לרוטינת השינון היומי שלך! כמות המידע שצברת היא אדירה!',
        name: 'הוספת 40 חפיסות שינון',
        tip: 'על הוספה של 40 חפיסות שינון כלשהן',
      },
      added_decks_x5: {
        desc: 'הוספת עד כה 5 חפיסות חדשות לרוטינת השינון היומי שלך',
        name: 'הוספת 5 חפיסות שינון',
        tip: 'על הוספה של 5 חפיסות שינון כלשהן',
      },
      avg_decks: {
        desc: 'ברשותך מספר חפיסות גבוה מן הממוצע למשתתף בפרויקט. קצבך הוא זה שקובע את קצב למידתם של שאר משתתפי הפרויקט!',
        name: 'קובע הקצב',
        tip: "ניתן בסוף כל שבוע. 5 נק' למי שלו מספר חפיסות גבוה מהממוצע. 2 נקודות נוספות למי שמספר החפיסות שלו כפול ויותר מהממוצע",
      },
      daily_study: {
        desc: 'השלמת השינון המתוזמן היומי שלך',
        name: 'שינון מתוזמן יומי',
        tip: 'השלמה של שינון מתוזמן יומי (למעט חפיסות חדשות שלא עברו 5 ימים ממועד הוספתן וכן כרטיסים אחים "קבורים")',
      },
      finished_course: {
        desc: 'עבורך אין יותר חדש תחת השמש, מוחך אוצר בתוכו את כל הידע הדרוש למבחן. כעת נותר לתחזק את הידע העצום שלך בתירגול בלתי פוסק!',
        name: 'יודע-כל',
        tip: 'השלמה של כל 4 נושאי הבחינה מבלי השארה של כרטיסים חדשים',
      },
      finished_topic: {
        desc: 'השלמת בהצלחה את הלמידה של אחד מ 4 נושאי הסילבוס!',
        name: 'סיום נושא',
        tip: 'השלמת שינון נושא שלם ללא כרטיסים חדשים. תקף רק על ארבעת הנושאים לבחינה (ללא חפיסות תרגול)',
      },
      monthly_study: {
        desc: 'השלמת את השינון המתוזמן שלך במשך חודש ברציפות! עקביות היא שם המשחק, ואת\\ה מסתמנים כשחקנים רציניים!',
        name: 'שינון חודשי',
        tip: 'רצף של 30 שינונים יומיים',
      },
      monthly_study_x3: {
        desc: 'השלמת את השינון המתוזמן שלך במשך 3 חודשים! הכיוון כבר נקבע וההצלחה מחכה בפתח שלך!!',
        name: 'שינון חודשי 3X',
        tip: 'השלמה של 3 שינונים חודשיים. לאו דווקא ברצף',
      },
      monthly_study_x5: {
        desc: 'וואו! השלמת את השינון המתוזמן שלך במשך חמישה חודשים! יש לך יכולת קוגנטבית וכוח רצון מעוררי קנאה השתאות והערצה!',
        name: 'שינון חודשי 5X',
        tip: 'השלמה של 5 שינונים חודשיים. לאו דווקא ברצף',
      },
      monthly_study_x6: {
        desc: 'וואו! השלמת את השינון המתוזמן שלך במשך שישה חודשים! נכנסת רשמית אל היכל התהילה של ALMOGMETHOD !',
        name: 'שינון חודשי 6X',
        tip: 'השלמה של 6 שינונים חודשיים, וזהו ההישג הגבוה ביותר בפרויקט שאפו עצום לך על שהצלחת להשיג זאת!!',
      },
      number_one: {
        desc: 'דורגת במקום הראשון בטבלת ההתמדה וההשקעה! השאיפה למצויינות והנכונות להשקיע הינם הדרך הבטוחה להצלחה, המשכ\\י כך!',
        name: 'מקום ראשון!',
        tip: 'דירוג במקום ראשון בטבלה',
      },
      study_11_hours: {
        desc: 'למדת לפחות 11 שעות ביום יחיד! ',
        name: '11 שעות לימוד ',
        tip: 'הימצאות במערכת או עם אתר פתוח אינה נחשבת "למידה" כמו גם השארת כרטיסייה פתוחה לזמן בלתי מוגבל',
      },
      study_11_hours_x10: {
        desc: "וואו! ביצעת 10 פעמים למידה יומית של לפחות 11 שעות. בדומה לגאון לאונרדו דה-וינצ'י בזמנו יש בך פנומנליות מתפרצת שרק תגבר עם הזמן עולם המדע והרפואה משתוקק לקבל אותך אליו!",
        name: '11 שעות לימוד 10X',
        tip: 'הימצאות במערכת או עם אתר פתוח אינה נחשבת "למידה" כמו גם השארת כרטיסייה פתוחה לזמן בלתי מוגבל',
      },
      study_11_hours_x3: {
        desc: 'ביצעת 3 פעמים למידה יומית של לפחות 11 שעות!!',
        name: '11 שעות לימוד 3X',
        tip: 'הימצאות במערכת או עם אתר פתוח אינה נחשבת "למידה" כמו גם השארת כרטיסייה פתוחה לזמן בלתי מוגבל',
      },
      study_11_hours_x5: {
        desc: ' וואו! ביצעת 5 פעמים למידה יומית של לפחות 11 שעות. ברור לנו שהצלחת לפענח את הנוסחה לרקיחת 350 במבחן הידע!',
        name: '11 שעות לימוד 5X',
        tip: 'הימצאות במערכת או עם אתר פתוח אינה נחשבת "למידה" כמו גם השארת כרטיסייה פתוחה לזמן בלתי מוגבל',
      },
      study_12_hours: {
        desc: 'ביצעת 12 שעות לימוד ביום יחיד!',
        name: '12 שעות לימוד',
        tip: 'הימצאות במערכת או עם אתר פתוח אינה נחשבת "למידה" כמו גם השארת כרטיסייה פתוחה לזמן בלתי מוגבל',
      },
      study_12_hours_x10: {
        desc: 'ביצעת 10 פעמים למידה יומית של לפחות 12 שעות. יכולות הריכוז וההתמדה שלך ייחודיות ונדירות ביותר, ברור לנו שזוהי רק ההתחלה ונכונו עבורך גדולות ונצורות!',
        name: '12 שעות לימוד 10X',
        tip: 'הימצאות במערכת או עם אתר פתוח אינה נחשבת "למידה" כמו גם השארת כרטיסייה פתוחה לזמן בלתי מוגבל',
      },
      study_12_hours_x3: {
        desc: 'ביצעת 3 פעמים למידה יומית של לפחות 12 שעות!!',
        name: '12 שעות לימוד 3X',
        tip: 'הימצאות במערכת או עם אתר פתוח אינה נחשבת "למידה" כמו גם השארת כרטיסייה פתוחה לזמן בלתי מוגבל',
      },
      study_12_hours_x5: {
        desc: 'וואו! ביצעת 5 פעמים למידה יומית של לפחות 12 שעות. בדומה לברוס מייקל אלברטס הוכחת שאין דבר או מכשול היכולים לגבור על הרעב והרצון שלך להצלחה.',
        name: '12 שעות לימוד 5X',
        tip: 'הימצאות במערכת או עם אתר פתוח אינה נחשבת "למידה" כמו גם השארת כרטיסייה פתוחה לזמן בלתי מוגבל',
      },
      study_9_hours: {
        desc: 'למדת לפחות 9 שעות ביום יחיד',
        name: '9 שעות לימוד',
        tip: 'הימצאות במערכת או עם אתר פתוח אינה נחשבת "למידה" כמו גם השארת כרטיסייה פתוחה לזמן בלתי מוגבל',
      },
      study_9_hours_x10: {
        desc: 'וואו! ביצעת 10 פעמים למידה יומית של לפחות 9 שעות. היכולת הזו מציבה אותך כמינימום גרגורי האוס!',
        name: '9 שעות לימוד 10X',
        tip: 'הימצאות במערכת או עם אתר פתוח אינה נחשבת "למידה" כמו גם השארת כרטיסייה פתוחה לזמן בלתי מוגבל',
      },
      study_9_hours_x3: {
        desc: 'ביצעת 3 פעמים למידה יומית של לפחות 9 שעות.',
        name: '9 שעות לימוד 3X',
        tip: 'הימצאות במערכת או עם אתר פתוח אינה נחשבת "למידה" כמו גם השארת כרטיסייה פתוחה לזמן בלתי מוגבל',
      },
      study_9_hours_x5: {
        desc: 'ביצעת 5 פעמים למידה יומית של לפחות 9 שעות! ',
        name: '9 שעות לימוד 5X',
        tip: 'הימצאות במערכת או עם אתר פתוח אינה נחשבת "למידה" כמו גם השארת כרטיסייה פתוחה לזמן בלתי מוגבל',
      },
      top_five: {
        desc: 'דורגת בחמשת המקומות הראשונים בטבלת ההתמדה וההשקעה, אין ספק שאם דרך זו תימשך התוצאות לא יאחרו לבוא!',
        name: 'פסגת ה- 5',
        tip: 'דירוג באחד מחמשת המקומות הראשונים בטבלה',
      },
      top_three: {
        desc: 'דורגת בשלושת המקומות הראשונים בטבלת ההתמדה וההשקעה, הישג לא פשוט בהתחשב באיכותם הגבוהה של האנשים מסביבך, כל הכבוד.',
        name: 'פסגת ה- 3',
        tip: 'דירוג באחד משלושת המקומות הראשונים בטבלה',
      },
      weekly_study: {
        desc: 'השלמת את השינון המתוזמן שלך במשך שבוע שלם',
        name: 'שינון שבועי',
        tip: 'השלמה של 7 ימים ברצף',
      },
      weekly_study_x10: {
        desc: 'וואו! השלמת את השינון המתוזמן שלך במשך עשרה שבועות! אנחנו יודעים ללא ספק שיש בך גאונות לימודית מתפתחת!',
        name: 'שינון שבועי 10X',
        tip: 'השלמה של 10 שינונים שבועיים. לאו דווקא ברצף',
      },
      weekly_study_x3: {
        desc: 'השלמת את השינון השבועי במשך 3 שבועות. זהו בהחלט הכיוון הנכון להמשיך ולהצליח!',
        name: 'שינון שבועי 3X',
        tip: 'השלמה של 3 שינונים שבועיים. לאו דווקא ברצף',
      },
      weekly_study_x5: {
        desc: 'וואו! השלמת את השינון המתוזמן שלך במשך חמישה שבועות! הראת פה ממש ניצנים של גאונות!',
        name: 'שינון שבועי 5X',
        tip: 'השלמה של 5 שינונים שבועיים. לאו דווקא ברצף',
      },
    },
    cardItem: {
      due: 'תוזמן ידנית לתאריך: {{date}}',
      menu: {
        comments: 'הערות למידה שיתופית',
        flip: 'סובב',
        fullscreen: 'מסך מלא',
        markFavorite: 'הוספה למועדפים',
        notes: 'הערות אישיות',
        unmarkFavorite: 'הסרה מהמועדפים',
      },
    },
    comments: {
      card: {
        delete: {
          cancel: 'ביטול',
          confirm: 'כן, מחק!',
          success: 'השיתוף בלמידה שלך נמחק בהצלחה!',
          text: 'האם הינכם בטוחים כי תרצו למחוק את השיתוף הכל-כך יפה שלכם בַּלמידה אותו חלקתם עם כלל הפרויקט? 💔',
          title: 'מחיקת למידה שיתופית',
        },
        dialog: { title: 'למידה שיתופית' },
        edit: {
          cancel: 'בטל',
          placeholder: 'ערוך את שיתוף הלמידה שלי...',
          submit: 'שמור',
          success: 'שיתוף הלמידה שלך עודכן !',
        },
        empty: {
          text: '" כל אחד יחיד ומיוחד, מחשבות רבות ונפלאות במוחו, שאין לאף אחד. נסה, ורק כך תגלה, שעולמך אותי ועולמי אותך, מעשיר וממלא! " (א. אלביליה)',
          title: 'אין עד כה למידה שיתופית מחברי הפרויקט לכרטיס זה, היו הראשונים לתקן זאת !',
        },
        item: {
          createdAt: 'נוצר בתאריך: {{date}}',
          editedAt: 'נערך לאחרונה בתאריך: {{date}}',
          readMore: 'קרא\\י עוד ...',
        },
        loadMore: 'טען הערות שיתופיות נוספות',
        post: {
          placeholder:
            'כאן תוכלו לרשום הערה לימודית \\ דרך זכירה מגניבה שלכם אותה תרצו לשתף עם שאר הסטודנטים בפרויקט ובתמורה תצברו פידבקים חיוביים אנונימיים מחברי הפרויקט. אל דאגה! תוכלו לערוך הערה זו או למוחקה בכל עת. שימו לב כי קיימת מגבלה להערה יחידה לְ- סטודנט\\ית לְ- כרטיס. אנו מאתגרים אתכם לייצר כמה שיותר הערות מועילות מעניינות ייחודיות ומובילות שכאלו ולזכות לתהילת עולם בפרויקט ובהפתעות נוספות! 🧠🩺',
          submit: 'פרסם',
          success: 'השיתוף הלימודי המדהים שלך פורסם בהצלחה! 🤩',
        },
        readMore: 'קרא\\י עוד ...',
        tip: { delete: 'מחיקה', dislike: 'לא מועיל', edit: 'עריכה', like: 'מועיל', report: 'דיווח' },
      },
    },
    common: { untitled: 'ללא כותרת' },
    contact: {
      action: 'שלח',
      email: 'אימייל',
      heading: 'צור קשר',
      message: 'תוכן ההודעה',
      name: 'שם וְ- שם משפחה',
      subject: 'נושא',
      success: 'הודעתך נשלחה אלינו בהצלחה, אנו נחזור אלייך במהירות המרבית! ',
      title: 'Title',
    },
    dashboard: {
      arena: 'לומדים יחד!',
      decks: 'הוספת חומר שינון',
      learn: 'הלימוד המתוזמן שלי',
      profile: 'הפרופיל האישי שלי',
      search: 'חיפוש בכרטיסים',
      simulations: 'הסימולציות שלי',
      statistic: 'הסטטיסטיקות שלי',
    },
    decks: {
      heading: 'הוספת חפיסות חומר שינון',
      learn: {
        add: 'הוסף לשינון המתוזמן שלי',
        edit: 'עריכת כרטיסים',
        remove: 'הסר מהשינון המתוזמן שלי (לא מומלץ)',
        reset: 'אפס את ההתקדמות שלי (לא מומלץ)',
      },
      training: 'ביצוע שינון ידני',
      warning: {
        add: {
          cancel: 'לא',
          confirm: 'כן!',
          text: 'האם אתם בטוחים שתרצו להוסיף חפיסה זו לשינון המתוזמן שלכם?',
          title: 'הוסף חפיסת שינון',
        },
        manual: {
          cancel: 'תוציא אותי מכאן',
          confirm: 'הבנתי ורוצה להמשיך',
          hide: 'אל תראה לי הערה זו בעתיד',
          text: 'שימו לב הינכם עומדים לבצע שינון ידני. למרות שיצרנו אפשרות זו, השינון הידני אינו מומלץ ועל אחריותכם בלבד! ',
          title: 'שינון ידני',
        },
        reject: {
          confirm: 'הבנתי. אגש ללמוד עכשיו',
          text: 'אינכם יכולים להוסיף חפיסה זו משום שברשותכם יותר מידי כרטיסים חדשים שלא שוננו עדיין! למדו את מה שברשותכם קודם, ואז ונסו שנית..',
          title: 'לא ניתן להוסיף חפיסת שינון',
        },
        remove: {
          cancel: 'ביטול',
          confirm: 'כן. הסר',
          text: 'האם אתם בטוחים שתרצו להסיר חפיסה זו? שימו לב כי כל ההתקדמות שנעשתה בחפיסה זו לא תישמר ותמחק לעולמי עד!',
          title: 'הסר חפיסת שינון (לא מומלץ)',
        },
        reset: {
          cancel: 'לא. קח אותי לחוף מבטחים',
          confirm: 'כן. אני בטוח\\ה',
          text: 'האם אתם בטוחים כי תרצו לאפס את התקדמותכם בחפיסה זו? שימו לב כי פעולה זו בלתי הפיכה! ',
          title: 'אפס את התקדמותי בחפיסה זו (לא מומלץ)',
        },
      },
    },
    delayedCards: {
      due: 'נדחה ידנית לתאריך {{date}}',
      edit: { label: 'תאריך חדש', submit: 'שמור', text: 'תזמון מחדש של הופעת הכרטיס', title: 'עריכת תזמון הכרטיס' },
      empty: {
        text: 'לא נמצאו כרטיסים שנדחו ידנית על ידך תוך שימוש בעקיפת האלגוריתם 🤩',
        title: 'לא נמצאו כרטיסים דחויים',
      },
      heading: 'כרטיסים שדחיתי ידנית (עוקף אלגוריתם)',
    },
    editor: {
      emojiPicker: {
        add_custom: 'הוספ\\י emoji ',
        categories: {
          activity: 'Activity',
          custom: 'Custom',
          flags: 'Flags',
          foods: 'Food & Drink',
          frequent: 'Frequently used',
          nature: 'Animals & Nature',
          objects: 'Objects',
          people: 'Smileys & People',
          places: 'Travel & Places',
          search: 'Search Results',
          symbols: 'Symbols',
        },
        pick: 'בחר\\י emoji…',
        search: 'חיפוש',
        search_no_results_1: 'Oh no!',
        search_no_results_2: 'לא ניתן היה למצוא את ה emoji המבוקש',
        skins: {
          1: 'Default',
          2: 'Light',
          3: 'Medium-Light',
          4: 'Medium',
          5: 'Medium-Dark',
          6: 'Dark',
          choose: 'Choose default skin tone',
        },
      },
    },
    error: {
      boundary: {
        button: 'חזור למסך הבית',
        text: 'אנחנו מצטערים אך נראה שמשהו אינו עובד כשורה, אנא צרו עמנו קשר על-מנת שנפתור בעיה זו במהירות האפשרית. תודה!',
      },
      not_found: {
        button: 'בחזרה אל מסך הבית',
        heading: '404 זה יותר מ 350 ולכן הדף לא נמצא לצערנו !',
        text: 'אולי פעם הייתי קיים אך היום כבר לא (דף זצ"ל בספרו: "הגיגים"), מצטערים על אי הנוחות!',
      },
    },
    general: { error: 'התרחשה תקלה, מצטערים על אי הנוחות...' },
    goal: {
      calendar: {
        alert: {
          done: 'סיימת !',
          low_study: 'כמות הכרטיסים הממוצעת ליום נמוכה מידי לשקלול וחישוב',
          no_data: 'אין מספיק נתונים',
        },
        tabs: {
          estimation: 'תחזית המערכת ע"פ ביצועייך הנוכחיים',
          history: 'ביצועייך הנוכחיים (כמויות משוערות בעתיד והכמויות הנחוצות להשגת תאריך היעד שקבעת)',
        },
        title: 'תכנון ומעקב יעדי למידה אישיים',
        tooltip: {
          bad: 'יעד כרטיסים: {{ addedCards }} / {{ minAddedCards }}<br />יעד הוספה: {{ doneCards }} / {{ todoCards }}<br/>יעד סימולציות: {{ finishedSimulations }} / {{ shouldBeSimulations }}<br />עמידה ביעד: {{ doneSimulations }} / {{ todoSimulations }}',
          estimated_finish: 'סיום למידה חזוי על-ידי המערכת',
          estimated_finish_after:
            'הסיום המשוער לאחר תאריך היעד שהצבת ב: {{ behindDays }} ימים, ממוצע כרטיסים: {{ avgCards }}, ממוצע כרטיסים הנחוץ: {{ needAvgCards }}, הפער שלי: {{ diffAvgCards }}',
          estimated_finish_before: 'ברכותיי, הסיום המשוער מקדים את תאריך היעד שהצבת 🥳',
          estimated_future_bad:
            'כמות כרטיסים משוערת: {{ estCards }}, כמות משוערת להוספה: {{ estDoCards }}, ממוצע: {{ avgCards }}, כמות כרטיסים ממוצעת נחוצה: {{ needAvgCards }}, הפער שלי: {{ diffAvgCards }}<br />כמות סימולציות משוערת: {{ estSimulations }}, נשארו לביצוע: {{ todoSimulations }}',
          estimated_future_good:
            'כמות כרטיסים משוערת: {{ estCards }}, כמות משוערת להוספה: {{ estDoCards }}, ממוצע: {{ avgCards }}<br />כמות סימולציות משוערת: {{ estSimulations }}, נשארו לביצוע: {{ todoSimulations }}',
          estimated_over:
            'כמות כרטיסים משוערת: {{ estCards }}, כמות משוערת להוספה: {{ estDoCards }}, ממוצע: {{ avgCards }}, ממוצע דרוש לעמידה ביעד: {{ needAvgCards }}, הפער שלי: {{ diffAvgCards }}<br />כמות סימולציות משוערת: {{ estSimulations }}, לביצוע: {{ todoSimulations }}',
          estimated_spare: 'ברכותיי, הקדמת את הלו"ז שקבעת זהו יום "אקסטרה" למידה עבורך 🧠🩺',
          finish: 'זהו תאריך היעד שהזנת לסיום הלמידה',
          future:
            'כמות כרטיסים דרושה: {{ shouldBeCards }}, כמות משוערת שתהיה: {{ estWillBeCards }}, המלצה להוספה יומית: {{ todoCards }}<br />כמות סימולציות נחוצה: {{ shouldBeSimulations }}, מתוכן ביצעתי {{ doneSimulations }}, נשארו לביצוע: {{ todoSimulations }}',
          good: 'כמות הכרטיסים שלי: {{ addedCards }} / {{ minAddedCards }}<br />הוספה: {{ doneCards }} / {{ todoCards }}<br/>יעד סימולציות: {{ finishedSimulations }} / {{ shouldBeSimulations }}<br />עמידה ביעד: {{ doneSimulations }} / {{ todoSimulations }}',
          no_data: 'אין נתונים',
          rest: 'לא מחושב',
        },
      },
      dialogs: {
        breakup: {
          cancel: 'ביטול',
          confirm: 'אישור',
          text: 'האם אתם בטוחים שתרצו לפרק את צמד הלמידה המופלא הזה?',
          title: 'לפני שנפרדים...',
        },
      },
      edit: {
        desc: 'הגדרות:',
        endDate: 'תאריך יעד לסיום הלמידה בפרויקט',
        hasPending: 'אין אפשרות לשינוי ההגדרות כאשר ישנם שינויים הדורשים את התייחסותך',
        heading: 'קביעת הגדרות ליעדי למידה',
        restDays: 'ללא שישי ושבת',
        simulations: 'כמות סימולציות רצויה לביצוע',
        startDate: 'תאריך התחלת הלמידה',
        submit: 'שמירת הגדרות יעדי הלמידה שלי',
      },
      heading: 'תכנון ומעקב אחר יעדי למידה אישיים',
      invite: {
        alerts: {
          accepted: 'את\\ה וְ- {name} תאומי יעדי למידה מעכשיו 👶=👶',
          declined: 'ההזמנה סורבה',
          denied: 'בקשתך לתיאום וסינכרון יעדי הלמידה אושרה',
          exists: 'קיימת כבר בקשת תיאום וסינכרון יעדי למידה פעילה!',
          invited: 'איזה קטע! החבר\\ה כבר שלחו אלייך הזמנה לתיאום וסינכרון יעדי למידה',
          invitee_not_free: 'לחבר\\ה זה כבר קיים תאומ\\ת יעדי למידה 😔',
          inviter_not_free: 'כבר קיים לך תאומ\\ת יעדי למידה 😔',
          revoked: 'הזמנה בוטלה',
          sent: 'הזמנה נשלחה',
        },
        buttons: { accept: 'אשר', decline: 'סרב', revoke: 'ביטול' },
        subline: { invited: 'הוזמנת להיות תאום למידה', requested: 'שלחת בקשה להיות תאום למידה' },
      },
      partner: {
        breakup: {
          button: 'פרק את סנכרון ותיאום יעדי הלמידה עם תאום הלמידה שלי',
          success: 'התפרקה החבילה, אינכם תאומי למידה יותר 💔',
        },
        heading: 'תאום יעדי למידה',
      },
      pendingConfig: {
        partner: {
          accept: 'אשר שינויים',
          decline: 'סרב',
          heading: 'מעדכן תצורת יעדי למידה',
          text: 'תאום הלמידה שלך עדכן את תצורת יעדי הלמידה שלכם ומחכה לתשובתך. תאריך התחלה {{ startDate }}, תאריך סיום {{ endDate }}, סימולציות {{ simulations }}.',
        },
        user: {
          cancel: 'ביטול',
          heading: 'ממתין לתאום יעדי הלמידה',
          text: 'ממתין לאישור תצורת הלמידה ע"י תאום ייעדי הלמידה שלך. תאריך התחלה {{ startDate }}, תאריך סיום {{ endDate }}, סימולציות {{ simulations }}.',
        },
      },
      progress: {
        added: 'נוסף',
        ahead: 'כמות הכרטיסים המקדימים את לוח הזמנים',
        behind: 'כמות הכרטיסים בפיגור בלוח הזמנים',
        expected: 'ע"פ לוח הזמנים',
        heading: 'התקדמות ביעדי הלמידה',
        left: 'כמות הכרטיסים שעוד נשארו להוספה ע"פ לוח הזמנים',
      },
      settings: {
        alerts: {
          accepted: 'אישרת את שינויי תצורת הלמידה',
          cancelled: 'ביטלת את שינויי תצורת הלמידה',
          declined: 'סירבת לשינויי תצורת הלמידה',
          denied: 'הגישה נדחתה',
          has_pending: 'יש לך שינויים הדורשים התייחסות',
          no_pending: 'אין לך שינויים הדורשים התייחסות',
          pending: 'תצורת הלמידה שלך עודכנה. המערכת מחכה לאישור תצורת הלמידה שקבעת ע"י תאום הלמידה שלך',
          updated: 'תצורת הלמידה שלך עודכנה',
        },
      },
      simulations: {
        ahead: 'כמות שמקדימה את לוח הזמנים',
        behind: 'כמות שמפגרת בלוח הזמנים',
        done: 'כמות שבוצעה',
        expected: 'ע"פ לוח הזמנים',
        heading: 'התקדמות ביעדי הסימולציות',
        left: 'הכמות שנשארה לביצוע ע"פ לוח הזמנים',
      },
      tabs: { partner: 'תאום הלמידה שלי', personal: 'אישי', settings: 'הגדרות תצורת יעדי למידה' },
      team: {
        alone: 'אנא הצטרפו לקבוצה ע"מ להזמין חבר\\ה בה להיות תאום יעדי למידה שלכם',
        heading: 'הזמנת תאום יעדי למידה',
        invite: 'הזמינ/י',
        subline: 'חבר\\ת קבוצה',
      },
    },
    guides: { heading: 'אנציקלופדיית סימלונים באתר' },
    intro: {
      add_subdeck: {
        add: {
          text: 'זהו הכפתור להוספת חפיסת השינון לשינון המתוזמן היומי שלכם. שימו לב שלאחר הוספת החפיסה, כובע המרגמה ישתנה מריק, למלא.',
          title: 'הוספת חפיסת שינון ',
        },
      },
      arena_players: {
        name: { text: 'כאן מוצג השם, ומיקום הסטודנט בטבלת ההתמדה וההשקעה', title: 'שם ' },
        nav: {
          text: 'כאן תוכלו לנווט בין טבלאות ההתמדה וההשקעה השונות. יחידים: כל סטודנט בפני עצמו. קבוצתי: ניקוד קבוצות. תוך-קבוצתי: ניקוד אישי שבין חברי הקבוצה בלבד.',
          title: 'ניווט בטבלאות ההתמדה וההשקעה',
        },
        points: { text: 'כאן מוצגות נקודות ההתמדה וההשקעה שברשות הסטודנט', title: 'נקודות התמדה והשקעה' },
        progress: {
          text: 'כאן מוצגת מגמת השיפור שלכם. חץ מצביע מעלה ואייקון של אדם מגלגל סלע במעלה ההר מציג בדיוק אתכם, משקיענים מתמידים המשתפרים מיום ליום! ',
          title: 'מגמות',
        },
        row: { text: 'כך מוצג סטודנט בטבלת ההשקעה וההתמדה', title: 'סטודנט' },
        team: {
          text: 'כאן מוצגת הקבוצה שאליה משתייך הסטודנט, במידה ואינו משתייך לאף קבוצה יופיע קו. לחיצה על הסטודנט תאפשר לכם להזמינו לקבוצתכם אם הוא פנוי, או לבקש להצטרף לקבוצתו.',
          title: 'קבוצה',
        },
      },
      arena_profile: {
        anonym: {
          text: 'מכיוון שאנו מבינים שלא כולם מעוניינים ששמם יקושר לנתונים כאלו ואחרים, ניתן ללחוץ על כפתור זה ולהכנס למצב אנונימי. במצב אנונימי תקבלו שם גנרי אך מגניב מתוך מאגר השמות שיצרנו, תמונתכם האישית תוסר, וכך תוצגו לשאר משתמשי הקורס. אל דאגה! תוכלו לצאת ממצב אנונימי בכל עת ולקבל את זהותכם האמיתית בחזרה. ',
          title: 'מצב אנונימי',
        },
        badges: {
          text: 'זהו הישג, אחד מיני רבים הקיימים באתר. בסוף כל יום נתוניכם יבחנו על ידי המערכת, ובמידה ונמצא כי השלמתם את הקרטריון להישג מסוים תקבלו הודעה כי מעתה הוא ברשותכם. כל הישג שכזה מזכה אתכם בנקודות התמדה והשקעה ומעלה את רמתכם האישית. שימו לב כי בהישגים "מורכבים" ניתן לראות כמה חזרות נשארו לביצוע, עד להשגה.',
          title: 'הישגי למידה והשקעה',
        },
        bar: {
          text: 'זהו הפרופיל האישי שלכם ב "לומדים יחד!" כאן תוכלו לראות את רמתכם, את מספר ההישגים שלכם, וכן את כמות הנקודות שצברתם בעבור התמדה והשקעה בלימודיכם. ',
          title: 'שורת הפרופיל האישי שלכם',
        },
        leaderboard: {
          text: 'טבלה זו נותנת ביטוי לכמות השקעתכם והתמדתכם בלימוד בלבד! (אין ביטוי לציונים כלשהם בסימולציות או בכל פרמטר אחר), אנו מאמינים כי הצלחתכם תלויה באופן ישיר בכמות השקעתכם בקורס וכלי זה נועד לאפשר לכם לעקוב אחריה ביחס לכלל, להסיק מסקנות להשתפר ולהצליח! ',
          title: 'טבלת התמדה והשקעה',
        },
        points: {
          text: 'נקודות התמדה והשקעה ניתנות לכם לכל אורך לימודכם באתר, על כלל משימות התמדה והשקעה שונות. באזור זה תוכלו לראות על מה בדיוק קיבלתם כל נקודה ונקודה, ולהמשיך לצבור עוד ועוד מהן ',
          title: 'היסטוריית נקודות',
        },
        team: {
          text: 'כאן תוכלו לנווט אל דף קבוצתכם או לחלופין ליצור \\ להצטרף לקבוצת למידה קיימת, במידה ואינכם מסונפים לאחת.',
          title: 'קבוצה',
        },
      },
      arena_teams: {
        members: { text: 'אלו הם חברי הקבוצה המחזקים ברמה הגבוהה ביותר בה של ההתמדה וההשקעה', title: 'חברי הקבוצה' },
        name: { text: 'זהו שם הקבוצה, הניתן לה ע"י המנהל שלה כשיצר אותה', title: 'שם ' },
        points: {
          text: 'כאן מוצגות נקודות ההשקעה וההתמדה הקבוצתיות. נקודות אלה הן ממוצע של כלל נקודות חברי הקבוצה שכן כל חברי הקבוצה ערבים זה לזה! (עם זאת אין השפעה קבוצתית כלשהי על המיקום האישי של כל סטודנט כיחיד) ',
          title: 'נקודות השקעה והתמדה קבוצתיות',
        },
        progress: {
          text: 'כאן מוצגת מגמת הקבוצה, האם היא במגמת שיפור יכולות התמדה והשקעה, או חלילה דווקא במגמת ירידה',
          title: 'מגמות',
        },
        row: { text: 'כך מוצגת קבוצה למידה, בטבלת ההשקעה וההתמדה', title: 'קבוצה ' },
      },
      dashboard: {
        arena: {
          text: 'פה תוכלו לראות ולעקוב אחר מידת ההשקעה וההתמדה שלכם בלימוד החומר, ליצור ולתחזק קבוצת למידה עם סטודנטים אחרים באתר, ולהרוויח "הישגי למידה" שיזכו אתכם בפרסים שונים בהמשך',
          title: '"לומדים יחד!"',
        },
        decks: {
          text: 'כאן שמור כל חומרי ALMOGMETHOD, מכרה הזהב הפרטי שלנו. לאחר קריאת החומר הרלוונטי בסילבוס המצוי על שם החפיסה, תוכלו להוסיפה, ולהכניס חומר זה לרוטינת השינון המתוזמנת שלכם כמו כן תוכלו לייצר כרטיסים אישיים בחפיסה האישית שלכם ולשננם כחלק מרוטינת השינון שלכם. שימו לב שלא ניתן להוסיף חפיסות רבות מידי מבלי שעברתם קודם על אלו הקיימות אצלכם.',
          title: 'חפיסות שינון',
        },
        learn: {
          text: 'כאן תתרחש רוב העבודה שלכם. לאחר הוספת חפיסות שינון, לחיצה על הכפתור הזה תפתח בפניכם את השינון המתוזמן לכם להיום ע"פ שיטת ה Active recall & Spaced repetition, שימו לב כי כמות הכרטיסים המתוזמנים תעלה משמעותית ככל שתתקדמו בלמידה.',
          title: 'השינון המתוזמן שלי',
        },
        posts: {
          text: 'כאן יוצגו בפניכם חדשות, פוסטים ועדכונים בצורה מתגלגלת ואוטומטית. אנו רוצים שתשארו תמיד מעודכנים בכל מידע רלוונטי לכם, מתוספת באתר ועד תאריך רישום למבחן. לחיצה על "קרא\\י עוד" תפתח בפניכם את הטקסט המלא של הפוסט. לסגירת חלון החדשות ניתן ללחוץ בכל מקום אחר באתר.',
          title: 'חדשות ועדכונים',
        },
        search: {
          text: 'כאן תוכלו לחפש חומר לימודי המצוי בטקסט כרטיסי האתר. בידכם האפשרות להגביל את החיפוש בשימוש במגוון פילטרי חיפוש שונים או לבחור בחיפוש כללי המתבצע בכלל כרטיסיות ALMOGMETHOD. גם כאלה שלא ברשותכם עדיין.',
          title: 'חיפוש בכרטיסים',
        },
        simulations: {
          text: 'כאן תוכלו לבצע סימולציות ושאלות תרגול שנכתבו על-ידנו. אנו מאמינים כי ביצוע סימולציות מתרגל אתכם בפתירת הבחינה וכן מציף בפניכם אזורי למידה שאולי צריכים חיזוק נוסף. עם זאת, חלק מהסימולציות לא תהיינה זמינות לכם ישירות בתחילת הקורס אלא רק בהמשכו.',
          title: 'הסימולציות שלי',
        },
        statistics: {
          text: "אנו מאמינים כי סטטי' הן כלי חשוב לזיהוי בעיות, ניתוחן, ומתן האפשרות לטיפול בהן בצורה כירורגית ממוקדת ויעילה! לכן יצרנו עבורכם מערכת סטטי' מקיפה ורחבה. מפילוח יכולות הלמידה שלכם ע\"פ השעה המסוימת ביום, כמויות הכרטיסים המתוזמנים לכם בעתיד, ממוצע כרטיסים ליום, דרך פילוח כמות הלחיצות שלכם על כל כפתור דירוג... ועד לניתוח יכולתכם העכשווית בכל נושא בבחינה בנפרד וניתוח הסימולציות שביצעתם! ",
          title: 'הסטטיסטיקות שלי',
        },
        welcome: {
          text: 'הצלחתכם במבחן הינה הצלחתנו האישית! לכן, לייעול שימושכם באתר וחיסכון בזמן הסתגלותכם אליו, הכנתי בעבורכם סיור מודרך שיעזור לכם לשלוט בכל אספקט בו לחלוטין! בעת שתכנסו לדבר מה בפעם הראשונה, אופיע לכם עם הסבר קצרצר (כמעט תמיד) מועיל (תמיד!) ואנחה אתכם. אני ממליץ בחום לבצע את הסיור עד סיומו, אך ניתן ללחוץ בכל עת על כל איזור מחוץ לבועת ההסבר ע"מ לסוגרה עד לפעם הבאה שתכנסו לרכיב זה או לקחת את הסיור שוב מחדש דרך הפרופיל שלכם. להמשך לחצו "הבא" 🤗🧠🩺',
          title: 'ברוכים הבאים לאתר ALMOGMETHOD !',
        },
      },
      decks: {
        list: {
          text: 'משמע, כאן אנו אוגרים את כל ה Goodies שלנו 🤤. מוצגים לכם ארבעת נושאי הבחינה: ביוכימיה, מולקולרית, ביותא, פיזיו. כשכל נושא שכזה מכיל בתוכו את כלל חפיסות השינון הקיימות לו.',
          title: 'זהו כל החומר הנמצא לשינון באתר ALMOGMETHOD',
        },
        open: {
          text: 'לחיצה כאן תפתח ותפרוס בפניכם את כלל החפיסות המסונפות לנושא. להמשך הסבר על הוספת חפיסת שינון מתוזמן, אנא לחצו על החץ ...',
          title: 'פריסת נושא',
        },
      },
      learn_answer: {
        card: {
          text: 'ישנם סוגים שונים של כרטיסים, כולם פשוטים לתפעול. אני בטוח שפגישה אחת או שניים עמם תספיק לכם על מנת לזהות את המתווה שבו יש לענות עליהם.',
          title: 'כרטיס שינון ALMOGMETHOD ',
        },
        difficulty: {
          text: 'כאן תוכלו לדרג כמה מן התשובה המוצגת הצלחתם לזכור ע"פ הקריטריון המופיע על כל כפתור דירוג. אנא זיכרו! דירוג זה הוא ליבת האלגוריתם, דירוג שגוי\\לא תואם את רמת זכירתכם, יוביל לניקוד כרטיס שגוי באלגוריתם ואי-זכירה שלכם את המידע בו בהמשך. לכן כל עיגול פינות בדירוג הכרטיס, בהכרח יוביל לפגיעה בציונכם במבחן הידע בסופו של דבר. אני מתחנן בפניכם להימנע מכך!',
          title: 'דירוג כרטיס (מקשים 1 עד 4 במקלדת בהתאמה)',
        },
      },
      learn_page: {
        close: {
          text: 'בעת לחיצה על כפתור זה תצאו מהשינון היומי, אל דאגה כל החומר ששיננתם עד עתה נשמר במאגרנו, ובחזרתכם תמשיכו מאותו המקום בו הפסקתם.',
          title: 'יציאה',
        },
        notes: {
          text: 'כאן תוכלו להוסיף לעצמכן הערות זכירה לכרטיס, תוספות של חומר שלדעתכם חשוב, או כל דבר אחר שבו תחפצו, לאחר כתיבת הערה וסגירת החלון ההערה תשמר אוטומטית. חשוב לציין כי הערות אלו הן אישיות לעינכם בלבד ואינן זמינות לאיש מלבדכם.',
          title: 'תוספות אישיות לכרטיס',
        },
        report: {
          text: 'כאן תוכלו לדווח לנו על טעות שמצאתם בכרטיס או על ניסוח בעייתי. ע"מ לשמור על יעילותנו באתר ועל יעילותכם, אנא בידקו עצמכם מול הרפרנס המוצג לכל כרטיס ו\\או בספרים עצמם לפני שתדווחו על טעות, אם עדיין אתם בטוחים כי מצאתם כזו אנא כללו בדיווחכם את מראי מיקום.',
          title: 'דיווח ',
        },
        show: {
          text: 'לאחר שקראתם את השאלה, ועניתם לעצמכם תשובה בניסוח אישי, תוכלו להשוות את תשובתכם לתשובת הכרטיס. אני ממליץ לפחות בהתחלה לנקוט בגישה "ייקית" ולא לוותר על אף פרט גדול כקטן, כמות המידע שתתעקשו לזכור תלוי בכם בלבד.',
          title: 'הצג תשובת ALMOGMETHOD (מקש space במקלדת)',
        },
      },
      learn_start: {
        due: {
          text: 'כאן מוצג פירוט הנושאים והכרטיסים המתוזמנים לכם להיום.',
          title: 'פירוט השינון המתוזמן לכם להיום!',
        },
        start: {
          text: 'לחיצה על כפתור זה תתחיל \\ תמשיך, את הצגת הכרטיסיות השינון המתוזמן להיום, על-פי סדר שנקבע במיוחד לכם ע"י אלגוריתם ה Spaced repetition המוטמע באתר.',
          title: 'התחלת השינון היומי',
        },
      },
      search: {
        decks: {
          text: "כאן תוכלו לבחור האם לבצע חיפוש בנושא בחינה ספציפי מכרטיסיות הנמצאות ברשותכם, או לחלופין לבצע 'חיפוש עומק' במאגר המלא של כרטיסיות ALMOGMETHOD.",
          title: 'חיפוש סלקטיבי',
        },
        input: {
          text: 'כתבו כאן את הטקסט שתרצו לחפש, לחיצה על מקש ה Enter או על זכוכית המגדלת בסוף השורה תבצע את החיפוש ...',
          title: 'שורת החיפוש',
        },
        mode: {
          text: 'כאן תוכלו לבחור בין 2 מצבי חיפוש שונים שיצרנו להגברת היעילות: 1) מצב ברירת המחדל "ללא סמיכות": במצב זה כלל המילים חייבות להופיע, אך לאו דווקא בסדר שבו הן נרשמו. 2) מצב "מדוייק" בו החיפוש מתבצע על כלל המילים בסדר ובסמיכות שבהם נכתבו. אנא כתבו "חלבון" בחיפוש ולחצו Enter להמשך הסיור...',
          title: 'מצב חיפוש',
        },
      },
      search_results: {
        back: {
          text: 'בלחיצה על הכפתור הזה תוכלו להציג את השדה האחורי (התשובה) של הכרטיס. לחיצה נוספת על הכפתור תחזיר את תצוגת הכרטיס למצב ההתחלתי, כשהתשובה מוסתרת.',
          title: 'הצגת שָׂדֶה אחורי',
        },
        full: {
          text: 'בלחיצה כאן תוכלו להציג את הכרטיס בתצוגה מקדימה על מסך מלא, ממש כפי שהוא מוצג לכם בעת ביצוע השינון המתוזמן היומי שלכם.',
          title: 'תצוגה  מַקְדִּימָה של הכרטיס',
        },
      },
      simu_stats: {
        hide: {
          text: 'ע"י לחיצה כאן תוכלו להסתיר את הממוצע הקורסיאלי באם תחפצו. שלושת הדמויות יהפכו לדמות אחת המצייגת אתכם בלבד. לחיצה נוספת תחזיר את לסטטי\' את עמודת הממוצע הקורסיאלי ואת מרחקכם ממנו (מעל \\ מתחת \\ שווה)',
          title: 'הסתרת הממוצע של שאר הסטודנטים באתר',
        },
      },
      simulation: {
        answer: {
          text: 'בעת לחיצה על המסיח עם כפתור שמאל של העכבר, המסיח הנוכחי ייבחר. לאחר הבחירה לא ניתן להסיר את הסימון, אלא רק לשנות בחירה לאחד המסיחים האחרים.',
          title: 'בחירת תשובה',
        },
        card: {
          text: 'זוהי שאלה יחידה בסימולציה (או התרגול). לכל שאלה 4 מסיחים שונים. זוהי השבלונה שבה נכתבים מבחני הידע.',
          title: 'שאלה',
        },
        dot: {
          text: 'נועד לאלו מכם שרוצים לבצע תהליך מחשבתי של "פסילת" מסיחים או סימונם כמועמדים "נכונים" לפני הבחירה הסופית. ביצוע פסילה מהירה: לחיצה על כפתור ימין בעכבר בכל מקום במסיח הנבחר תשנה את הנקודה האפורה לְ- X אדום. לחיצה נוספת שכזו תסיר את ה X. לחלופין ניתן לסמן פסילת מסיח בלחיצה בלחצן השמאלי בעכבר ישירות על הנקודה האפורה. לחיצה נוספת שכזו תסמן את המסיח כ "מועמד נכון" V ירוק. אדגיש שסימונים אלו נועדו אך ורק בשבילכם ואין להם כל משמעות אחרת.',
          title: 'אופציה לביצוע תהליך חשיבתי לפני סימון תשובה',
        },
        header: {
          text: 'זוהי שורת המצב של הסימולציה (או התרגול), בה תראו את שם הסימולציה, הזמן שחלף מתחילתה, את התקדמותכם בסימולציה, וכמות השאלות בסימולציה (120 שאלות, למעט אם מדובר בסימולציית מרתון תרגול לנושא בחינה ספציפי)',
          title: 'שורת המצב',
        },
        options: { text: 'כאן לעולם יהיו 4 מסיחים, מתוכם תוכלו לבחור מסיח אחד כעונה על השאלה.  ', title: 'מסיחים' },
        question: { text: "כאן מופיעה השאלה בצורת טקסט \\ תמונה וכד'", title: 'גוף השאלה' },
        topic: {
          text: 'גם כאן מדובר בשבלונה שבה נכתבים מבחני הידע. לעולם יהיו שאלות : 1-30 מביוכימיה, 31-60 ממולקולרית, 61-90 מביותא, 91-120 מפיזיו. אמנם במבחן הידע לא יופיע לכם הנושא, אך לתרגול כדאי שתתחילו להטמיע את הסידור הרלוונטי.',
          title: 'זהו הנושא שבה עוסקות השאלות המוצגות כרגע',
        },
      },
      simulation_result: {
        difficulty: {
          text: 'כאן תוכלו לראות את תוצאתכם ביחס לרמת קושי השאלה. לא דין טָעוּת ב 4 שאלות ברמת קושי קלה מאוד, המחייב בדק בית יסודי לטָעוּת ב 4 שאלות ברמת קושי גבוהה ביותר, המעידה קרוב לוודאי כי בידכם ידע נרחב אך ניתן לחדדו עוד לשלמות. ',
          title: 'פילוח נתונים ע"פ רמת קושי',
        },
        finish: {
          text: 'בלחיצה על הכפתור הזה תוצאו מן הסימולציה ותועברו אל מרכז הפעולות, בד בבד נתוני הסימולציה ייכנסו אל הסטטיסטיקות שלכם ויישמרו.',
          title: 'יציאה מהסימולציה (או התרגול)',
        },
        result: {
          text: 'לאחר הגשת הסימולציה (או התרגול) לבדיקה, יופיע חלון זה שבו תוכלו לראות את תוצאתכם בסימולציה ואת ציונכם בה כאחוז תשובות נכונות מכלל השאלות שנענו. תוכלו לגשת ישירות לתיחקור וצפייה בתשובותינו לכל שאלה ושאלה בלחיצה על מספרי השאלות המופיעים לכם מימין. ע"מ לייעל את זמנכם, שאלות שנענו נכון יופיעו בירוק, ואילו שאלות שנענו שגוי יופיעו באדום.',
          title: 'תוצאות סימולציה',
        },
      },
      simulation_sidebar: {
        details: {
          text: 'אלו פרטי הסימולציה (או התרגול), הציונים השונים שלכם בסימולציה, וניקוד הסימולציה. ניקוד הסימולציה משוקלל כך שככל שהסימולציה מכילה יותר שאלות ברמות קושי גבוהה, ניקודה גַדֵל! (ניקוד זה קובע את סיווג הקושי של הסימולציה, גם ביחס לסימולציות המסווגות באותה רמת הקושי). אנא לחצו על כפתור התחלת הסימולציה להמשך הסיור ...',
          title: 'סימולציה',
        },
      },
      simulations: {
        list: {
          text: 'כאן תמצאו את הסימולציות הזמינות לכם (לאו דווקא את כלל הסימולציות הקיימות) וכן את סימולציות "שאלות התרגול" לכל פרק. סימולציות אלו ניקודן ודירוגן נעשו ע"י פרמטרים מוחלטים שנקבעו מראש. לכן לא דין סימולציה "קלה" כדין סימולצית "נישה" הקשה פי כמה ממבחן הידע הרגיל. כל השאלות שתפגשו כאן נכתבו על-יָדִי \\ אושרו על ידי, ולכן איכותן זהה וקבועה. אנא בחרו "סימולצית סיור" ע"מ להמשיך ...',
          title: 'הסימולציות שלי',
        },
        results: {
          text: 'כאן נשמרות כל הסימולציות שבצעתם לפי סדר עשייתן. אנחנו מעודדים תיחקור סימולציות איכותי שהינו חשוב להפקת לקחים והשלמת פערי חומר שאולי לא צפו עד כה בדרכים אחרות.',
          title: 'מערכת תיחקור סימולציות',
        },
      },
      stats: {
        page: {
          text: 'כאן תוכלו למצוא סטטיסטיקות עבור כל פרמטר ביכולות הלמידה והזיכרון שלכם! המתכנתים שלנו עמלו לילות כימים ע"מ להביא ללומדים דרכנו, אתם, פילוח של כלל יכולותיכם. ניתוח נתונים פרטני שכזה יציף בפניכם מכשולים גלויים וסמויים עמם אתם מתמודדים. כך תוכלו לשפר ולהשתפר ללא הרף וכן לשמר יכולות וחוזקות קיימות.',
          title: 'הסטטיסטיקות שלי',
        },
        simulations: {
          text: 'כאן תוכלו למצוא את ניתוח נתוני הסימולציות שביצעתם. אם כסימולציה בודדת ו\\או ניתוח של נושאי הבחינה בה ואם בראייה רוחבית על כלל הסימולציות וניתוח יכולתכם פר נושאי הבחינה \\ סימולציה ספציפית, כתלות בתקופת זמן שתבחרו. ',
          title: 'ניתוח נתוני הסימולציות שלי',
        },
      },
      subdecks: {
        active: {
          text: "כובע המרגמה (כן גם לי לא היה מושג קלוש שזה שמו, עד לכתיבת סיור זה) הינו אינדקציה להימצאות חפיסה זו בשינון המתוזמן שלכם. ברגע שכובע המרגמה מלא, חפיסה זו נמצאת בשינון המתוזמן שלכם, כפי שמוצג בחפיסה הנוכחית. אנא לחצו על החפיסה הבאה 'ביוכימיה פרק 2' ע\"מ להמשיך את הסיור ...",
          title: 'חפיסה בשינון',
        },
        list: {
          text: 'זוהי רשימת החפיסות המסונפות לנושא כשהן מוצגות בסדר שיצרנו על-מנת להקל עליכם את הניווט בהוספתן מבלי לוותר על למידה רציפה איכותית והדרגתית של החומר',
          title: 'חפיסות שינון',
        },
        open: {
          text: 'כפתור "הוספת\\הסרת החפיסה" מוסתר על-ידנו כברירת-מחדל. לחיצה על החץ תפתח בפניכם את האפשרות להוסיף (או רחמנא ליצלן, לא עלינו, להסיר) חפיסת שינון.',
          title: 'פתיחת חפיסת שינון',
        },
      },
      team_messages: {
        messages: {
          text: 'כאן כל אחד מחברי הקבוצה יכול לכתוב הודעות לשאר החברים בה. אנחנו מברכים כתיבת משפטי מוטיבציה מרעננים, דיווח התקדמות לשאר החברים וכן הטלת שאלות מתוחכמות על החומר שיצרתם לגירוי ושיפור יכולת החשיבה הקבוצתית. אך, גם השווצה בדג הזהב החדש שקניתם הינה הודעה לגיטימית לחלוטין כמובן ...',
          title: 'דיבור בין חברי הקבוצה',
        },
      },
      ui: { done: 'סיום', next: 'הבא', prev: 'חזרה' },
    },
    learn: {
      comments: { tip: 'הערות ציבוריות' },
      delay: {
        label: 'ימים',
        submit: 'תזמן',
        text: 'בעוד כמה ימים תרצו שהכרטיס יופיע שוב?',
        tip: 'תזמון כרטיס ידנית',
        title: 'תזמון כרטיס ידנית (עוקף אלגוריתם)',
      },
      done: {
        button: 'קח אותי אל מרכז הפעולות שלי',
        text: 'אני גאה בך שהגעת ליעד וסיימת את כלל החומר המתוזמן לך להיום. התמדה ונחישות הינן הסוד להצלחה, יום אחרי יום, ללא ויתור! מצפה לראותך פה גם מחר',
        title: 'גם דברים טובים נגמרים...',
      },
      empty: {
        button: 'קח אותי למאגר חפיסות השינון ',
        text: 'אינך יכול\\ה לשנן מה שאינו קיים! הוסיפו קודם-כל לפחות אחת מחפיסות השינון וחזרו לכאן על מנת לשננה עד סוף כל הדורות או עד הקבלה לרפואה (המוקדם מבין השניים)',
        title: 'הוסיפו חפיסת שינון על מנת להתחיל לשנן',
      },
      favorite: { mark: 'הוספה למועדפים', unmark: 'הסרה מהמועדפים' },
      heading: 'שינון מתוזמן יומי',
      later: {
        button: 'קח אותי למרכז הפעולות שלי',
        text: 'נשארו לך כּ {{cards}} כרטיסים בשינון הידני שלך להיום, המתוזמנים החל מהשעה {{time}} ',
        title: 'חזור\\י מאוחר יותר על-מנת להמשיך לשנן! (יש לך 24 שעות לכך)',
      },
      nodue: {
        button: 'קח אותי למרכז הפעולות שלי',
        text: 'לא מצאנו עוד כרטיסיות המתוזמנות לך להיום! אם בכל זאת ברצונך להמשיך לצפות בכרטיסיות, ניתן לשנן באופן ידני כרטיסיות לפי בחירתך ב "שינון הידני" שבמאגר חפיסות השינון ',
        title: 'לא נמצאו כרטיסיות מתוזמנות',
      },
      notes: {
        heading: 'תוספות אישיות לכרטיס',
        placeholder:
          "כאן תוכלו לכתוב לכם הערה \\ דרך זכירה \\ תוספת \\ חמשיר או צ'יזבט לכרטיס זה. וכן, רק אתם רואים את הכתוב כאן, תרגישו חופשי להתפרע ...",
        tip: 'תוספות אישיות לכרטיס',
      },
      report: {
        cancel: 'ביטול',
        label: 'תוכן ...',
        placeholder: 'ארצה לדווח על כרטיס זה מכיוון ש...',
        submit: 'שלח\\י דיווח',
        success: {
          button: 'סיים',
          text: 'תודה על המשוב שלך ועל עזרתך בשיפור ודיוק החומרים שלנו ב ALMOGMETHOD!',
          title: 'דיווחך נשלח אלינו בהצלחה',
        },
        text: 'מִכָּל מְלַמְּדַי הִשְׂכַּלְתִּי, וּמִתַּלְמִידַי יוֹתֵר מִכֻּלָּם! אנו בני אדם ויכולים לטעות, אם הינכם בטוחים כי מצאתם טעות או ניסוח בעייתי בכרטיס זה, אנא עדכנו אותנו בתמציתיות ובצירוף מראי-מיקום מהספרים כרפרנס. תודה על עזרתכם',
        tip: 'דיווח על טעות בכרטיס',
        title: 'דווח\\י על טעות בכרטיס',
      },
      start: {
        button: 'לחצו עליי להתחלת השינון המתוזמן להיום 🤓',
        deck: 'חפיסות בשינון המתוזמן לי להיום',
        due: 'מתוזמן להיום',
        new: 'חדשים',
        progress_button: 'התקדמות ביעדי הלמידה שלי',
      },
      study: {
        again: 'לא ידעתי',
        continue: 'המשך',
        easy: 'ידעתי הכל',
        good: 'סביר',
        hard: 'דרוש רענון',
        leave_confirm:
          '"זהו תהליך ארוך, אך לפרוש לא יזרז אותו". זה קשה. זה מעייף. זה מתיש. אני יודע! אבל רק כך תגיעו לתוצאות שיספקו אתכם! האם בכל זאת תרצו לצאת מהשינון היומי המתוזמן? (תוכלו להמשיך מהמקום שהפסקתם מאוחר יותר)',
        none: 'ללא',
        show: 'תשובת ALMOGMETHOD ',
        skip: 'דלג כעת, הכרטיס יוצג מאוחר יותר בשינון היום',
      },
    },
    maintenance: {
      heading: 'אתר הפרויקט בתהליך תחזוקה זמני ומהיר',
      text: 'כרגע מתבצעת על-ידנו תחזוקה של אתר הפרויקט, אל דאגה אנו נחזור שוב בהקדם וחזקים מתמיד!!',
    },
    membership: {
      expired: {
        payButton: 'לרכישת מנוי נוסף, עם מתנה קטנה... מאיתנו',
        text: 'המנוי שלך בפרויקט ALMOGMETHOD הסתיים ולכן כאן דרכינו נפרדות, אנו תקווה שהפרויקט היה לך לתועלת וקידם אותך להשגת מטרותיך, אנו זמינים עבורך תמיד גם בעתיד אם לעצה לקראת לימודי הרפואה שלך או סתם התייעצות חשובה על סוג משחת השיניים המועדפת עלינו ❤️',
        title: 'גם דברים טובים נגמרים ...',
      },
      newbie: {
        payButton: 'רכישת מנוי',
        text: 'אנו שמחים על שפתחת משתמש בפרויקט ALMOGMETHOD והראית את רצונך להצטרף ללמידה בשיטה היעילה ביותר והיחידה המוכחת מחקרית לשינון מאגרי מידע לאורך זמן, לקבלת גישה מלאה לתכני אתר הפרויקט אנא השלמ\\י את תהליך רכישת המנוי',
        title: 'ברוכים הבאים',
      },
      pending: {
        text: 'אנו מכינים את האתר עבורך. הקורס עליו שילמת יחל בתאריך: {{startDate}} וממנו האתר יהיה זמין עבורך עד לתאריך סיום הקורס הנמצא בפרופיל האישי שלך',
        title: 'עוד קצת ומתחילים ...',
      },
    },
    modals: {
      note: {
        placeholder:
          "כאן תוכלו לקרוא לכתוב או אף לערוך את ההערה \\ דרך הזכירה \\ התוספת \\ החמשיר או הצ'יזבט הפרטיים הנמצאים בכרטיס זה. וכן, רק אתם רואים את הכתוב כאן, תרגישו חופשי להתפרע",
        title: 'תוספות אישיות לכרטיס',
      },
    },
    navbar: {
      delayedCards: 'רשימת כרטיסים שדחיתי ידנית',
      help: 'פירוש סמלים באתר',
      logout: 'יציאה מהחשבון שלי',
      profile: 'הפרופיל שלי',
      studyProgress: 'התקדמות ביעדי הלמידה שלי',
      userCards: 'כרטיסים במאגר האישי',
    },
    notifications: {
      badge: 'כל הכבוד! יש בידך הישג חדש: {{badge}}.',
      heading: 'הודעות',
      'invite-accept': 'הזמנתך ל{{user}} נענתה בחיוב!.',
      'invite-block': 'הזמנתך ל{{user}} נחסמה.',
      'invite-decline': 'הזמנתך ל{{user}} לצערנו נדחתה.',
      'invite-delete': 'הזמנתך מקבוצת {{team}} נמחקה.',
      'invite-receive': 'קיבלת הזמנה להצטרפות לקבוצת {{team}}.',
      'request-accept': 'וואו בקשתך להצטרך לקבוצת {{team}} אושרה! 🥳',
      'request-block': 'בקשתך להצטרף לקבוצת {{team}} נחסמה ⛔',
      'request-decline': 'בקשתך להצטרף לקבוצת {{team}} לצערנו נדחתה',
      'request-delete': 'בקשת {{user}} להצטרף לקבוצתך, נמחקה.',
      'request-receive': 'איזה כבוד! {{user}} מבקש\\ת להצטרף לקבוצתך.',
      'study-invite-accept': 'הזמנתך לתיאום למידה עם {{user}} אושרה.',
      'study-invite-decline': 'הזמנתך לתיאום למידה עם {{user}} סורבה.',
      'study-invite-receive': 'קיבלת בקשה לתיאום למידה מְ- {{user}}.',
      'study-invite-revoke': 'התעלם מהצעת תיאום הלמידה עם {{user}} .',
      'study-partner-breakup': 'בטל\\י את תיאום הלמידה שיש לך עם {{user}}.',
      'study-settings-accepted': 'תאום הלמידה אישר\\ה את הגדרות הלמידה.',
      'study-settings-cancelled': 'תאום הלמידה ביטל\\ה את הגדרות הלמידה.',
      'study-settings-declined': 'תאום הלמידה סירב\\ה להגדרות הלמידה.',
      'study-settings-pending': 'תאום הלמידה שלך שינה את הגדרות הלמידה שלכם. אנא אשר\\י שינויים אלו.',
      'team-disband': 'לא תמיד זה עובד, לצערנו קבוצתך פורקה.',
      'team-kickout': 'הודחתך מחברותך בקבוצת {{team}}, לבירור אנא פנו אל מנהל הקבוצה.',
      'team-leave': '{{user}} עזב\\ה את קבוצתך.',
      'team-member-kickout': '{{user}} הודח\\ה מקבוצתך 😯',
      'team-points-reset': 'צבירת נקודות הקבוצה אופסה.',
    },
    pdeck: {
      create: 'הוספת כרטיס חדש',
      delete: {
        cancel: 'לא. טוב ששאלת.',
        confirm: 'כן, מחק!',
        text: 'האם אתם בטוחים כי תרצו למחוק כרטיס זה?',
        title: 'אזהרת מחיקת כרטיס!',
      },
      empty: {
        button: 'הוספת כרטיס',
        text: "חפיסת השינון שלכם ריקה, אנא לחצו על כפתור 'הוספת כרטיס' ",
        title: 'החפיסה האישית שלך ריקה',
      },
      form: { create: 'הוספת כרטיס חדש', node: 'סוג כרטיס', update: 'עריכת כרטיס' },
      heading: 'חפיסה אישית',
      notfound: { text: 'לצערנו לא הצלחנו למצוא תוצאות התואמות את חיפושך : "{{query}}"', title: 'לא נמצאו כרטיסים ' },
      search: { placeholder: 'חפש...' },
    },
    posts: { heading: 'חדשות', readMore: 'קרא\\י עוד...' },
    profile: {
      avatar: {
        allowed: 'פורמטים נתמכים :  jpeg, jpg, png, gif',
        cancel: 'בטל',
        change: 'שנה תמונה',
        confirm: 'אשר',
        delete: 'מחק תמונה',
        description: 'ניתן לגרור תמונה על-מנת לחותכה באופן הרצוי',
        max: 'גודל מרבי 5 MB',
        notimg: 'הקובץ שהועלה אינו נתמך. אנא העלו קובץ רק בפורמטי הבאים: jpeg ,png או svg file.',
        title: 'שנה את התמונה שלי',
        toobig: 'הקובץ שנבחר גדול מידי בעבורנו, הגודל המרבי הינו: 5MB.',
        upload: 'העלה תמונה',
        zoom: 'Zoom',
      },
      basic: {
        email: 'האימייל שלי',
        name: 'שמי וְ- שם משפחתי',
        password: 'Current password',
        save: 'שמירת שינויים (מנהל בלבד)',
        title: 'לשינוי או תיקון פרטיכם האישיים אנא צרו עמנו קשר',
      },
      error: { pwIncorrect: 'הסיסמאות אינן תואמות! אנא נסו שוב' },
      heading: 'הפרופיל האישי שלי',
      learning: {
        spread: {
          default: 'הצג ע"פ המלצת ALMOGMETHOD',
          distribute: 'הצג בצורה אקראית',
          first: 'הצג ראשונים (לפני הכרטיסים המתוזמנים)',
          label: 'עלייך לרענן את האתר על-מנת שהשינויים שביצעת ייכנסו לתוקף',
          last: 'הצג אחרונים (לאחר הכרטיסים המתוזמנים)',
        },
        title: 'הגדרת תצוגת כרטיסים חדשים (כחולים) בשינון היומי',
      },
      membership: {
        heading: 'מנוי',
        text: 'המנוי שלך באתר הפרויקט הינו מתאריך ה {{startDate}} ועד לתאריך ה {{endDate}}.',
      },
      notifications: {
        marketing: { primary: 'עדכונים והצעות', secondary: 'הנחות, הצעות ייחודיות ועוד ...' },
        reminder: {
          primary: 'הפעל תזכורת להשלמת השינון היומי שלי',
          secondary:
            'בעת הפעלת אופציה זו תקבל\\י תזכורת במייל על שינון יומי שטרם הושלם לפני השעה 21:00! (עליכם לסיים את השינון עד 00:00)',
        },
        title: 'הודעות',
      },
      password: {
        currentPassword: 'הסיסמה הנוכחית שלי',
        newPassword: 'סיסמה חדשה',
        newPasswordAgain: 'כתבו סיסמה חדשה שנית ',
        save: 'שמור את הסיסמה החדשה שלי',
        title: 'שנה את הסיסמה שלי',
      },
      remorse: {
        cancel: 'ביטול',
        confirm: 'כן, בבקשה',
        text: 'הצלחתכם חשובה לנו, אנא שקלו זאת בשנית לפני שתחליטו....',
        title: 'האם אתם בטוחים?',
      },
      tutor: {
        button: 'התחל סיור מודרך חדש',
        heading: 'סיור מודרך באתר',
        text: 'לחיצה על "התחל סיור מודרך חדש", תאפס את התקדמותכם בסיור המודרך האחרון שביצעתם, ויפתח בפניכם סיור מודרך חדש על כלל חלקיו. אני מזכיר כי ניתן לצאת מהסיור על-ידי לחיצה על כל מקום באתר.',
      },
    },
    search: {
      deck: { all: 'חיפוש בכל חומר ALMOGMETHOD', label: 'נושא בחינה' },
      decks: {
        label: 'מיין על-פי נושא\\י בחינה',
        placeholder: 'החיפוש יבוצע בכל חומר הקורס, גם כזה שאינו נמצא בשינון היומי שלך',
      },
      fulltext: 'מילים אלו חייבות להימצא בסמיכות אחת לשניה',
      heading: 'חיפוש בכרטיסים',
      keywords: 'מילים אלו חייבות להימצא, אך אין חובה לסמיכות.',
      node: { all: 'חפש בכל סוגי הכרטיסים', label: 'מיין על-פי סוג הכרטיס' },
      notfound: {
        text: 'לצערנו, לא הצלחנו למצוא כרטיסים המכילים "{{query}}", אנא נסו לבחור במנגנון החיפוש \'ללא חובת סמיכות\' להגדלת הסיכוי לתוצאות או נסחו חיפוש שונה. במידה ועדיין אינכם מוצאים את מבוקשכם, זהו הזמן לגשת ישירות אל המקור ... הספרים 🕵️‍♀️.',
        title: 'לא נמצאו כרטיסים התואמים את חיפושך',
      },
      query: { placeholder: 'כתבו כאן מה תרצו לחפש, לכל הפחות 3 תווים ...' },
      results: { found: 'נמצא {{count}} כרטיסים התואמים את חיפושך' },
      subdecks: { label: 'מיין על-פי חפיסה' },
      tabs: { all: 'חיפוש בכל חומר ALMOGMETHOD' },
    },
    shop: {
      failed: {
        button: 'הבנתי',
        text: 'לא הצלחנו להשלים את תהליך התשלום, אנא רעננו את הדף ונסו שוב. במידה ואינכם מצליחים להירשם אנא פנו אלינו דרך תיבת יצירת הקשר שבאתר ואנו נפתור עבורכם את הבעיה.',
        title: 'לא בוצע תשלום',
      },
      pending: { button: 'רענן', text: 'תהליך התשלום עדיין לא הושלם במלואו...', title: 'תשלום בהמתנה' },
      success: {
        button: 'קח אותי אל מסך הבית',
        text: 'ברשותך מנוי לאתר הפרויקט ALMOGMETHOD. אנו ממליצים לך לקרוא את המסמך המכווין ולראות את סרטון ההדרכה באתר לפני תחילת השימוש באתר, קישור אליהם תמצאו בלוח המודעות שהגישה אליו נמצאת בצד שמאל עליון של האתר.',
        title: 'התשלום התקבל בהצלחה!',
      },
    },
    simulate: {
      chart: {
        correct: 'נענו נכון',
        questions: '{{count}} שאלות',
        title: 'פילוח נתונים על-פי רמת קושי השאלות ',
        wrong: 'נענו שגוי',
      },
      footer: {
        endview: 'סיים תיחקור סימולציה',
        finish: 'קח אותי למרכז הפעולות שלי',
        stop: 'הגשה סופית של הסימולציה לבדיקה ',
      },
      header: { elapsed: 'מתחילת הסימולציה', questions: '{{count}} שאלות' },
      leaveConfirm:
        'אנחנו לא זונחים סימולציות בקור ובגשם, עצובות ולא גמורות! האם את\\ה בטוחים שאתם רוצים לוותר עליה ולצאת למרות זאת? 😒',
      question: {
        explanation: 'הסבר ALMOGMETHOD ורפרנס :',
        mark: 'סמן לְחזרה',
        no: 'שאלה מספר: {{number}}',
        reason: {
          filler: 'זוהי שאלה שנועדה להשלים ל 120 שאלות לסימולציה',
          marked: 'זוהי שאלה שסומנה לְחזרה!',
          wrong: 'זוהי שאלה ששגית בה!',
        },
        submit: 'הצג תשובה',
        unmark: 'מחק סימון',
      },
      report: {
        cancel: 'ביטול דיווח',
        message: 'תוכן',
        placeholder: 'ארצה לדווח על שאלה זו מכיוון ש...',
        submit: 'שלח דיווח',
        success: {
          button: 'סיים ',
          text: 'תודה על המשוב שלך ועל ניסיונך לשפר אותנו, אנו מערכים זאת!',
          title: 'דיווחך נשלח בהצלחה',
        },
        text: 'אנחנו בני אדם ויכולים לטעות, אם אתם בטוחים כי מצאתם טעות אנא עדכנו אותנו בצירוף מראי מיקום מהספרים. תודה על עזרתכם',
        tip: 'דווח על שאלה זו',
        title: 'דווח על שאלה זו',
      },
      stats: {
        correct: 'נענו נכון:',
        minutes: '{{minutes}} דקות',
        score: 'ניקודך משוקלל קושי:',
        time: 'משך הזמן:',
        unanswered: 'לא נענו:',
        wrong: 'נענו שגוי:',
      },
    },
    simulations: {
      builder: {
        dialog: { title: 'יצירת סימולציה חדשה' },
        form: {
          chapters: { label: 'פרקים', value: '{{topic}}: {{chapter}}' },
          description: 'תיאור ',
          difficulties: 'קושי',
          limit: {
            topic: 'הציבו מספר אם ברצונכם להגביל את מספר השאלות לנושא:',
            total: 'הציבו מספר אם ברצונכם להגביל את מספר השאלות הכללי',
          },
          name: 'שם הסימולציה',
          question_filter: {
            flagged_failed: 'רק מסומנות בדגל וְ- טעויות',
            label: 'בורר השאלות',
            none: 'כלל השאלות',
            unseen: 'רק שאלות שלא פתרתי עדיין',
          },
          question_type: { all: 'כלל השאלות', label: 'סוג השאלות', practice: 'שאלות תרגול', simu: 'שאלות סימולציה' },
          shuffle_options: 'ערבב את סידור המסיחים',
          shuffle_questions: 'ערבב את סידור השאלות',
          shuffle_topics: 'ערבב בין הנושאים',
          submit_each_question: "מצב מענה של 'שאלה-תשובה'",
          tags: 'תגיות',
          topics: 'נושא',
        },
        steps: {
          back: 'חזרה',
          config: 'בחירת שאלות',
          create: 'יצירת הסימולציה',
          next: 'הבא',
          save: 'יצירה',
          summary: 'סיכום',
        },
        summary: {
          amount: 'כמות:',
          chapters: { label: 'פרקים:', value: '{{topic}}: {{chapter}}' },
          difficulties: 'קושי:',
          non_selected: 'לא נבחר',
          question_filter: 'בורר השאלות:',
          question_type: 'סוג השאלות:',
          tags: { label: 'תגיות:', value: '{{tag}} ועוד {{count}} אחרות...' },
          topic_amount: '{{count}} (מתוך כְּ- {{total}} זמינות)',
          topics: 'נושאים:',
          total: 'סה"כ:',
          total_value: '{{count}} (מתוך כְּ- {{total}} זמינות)',
        },
      },
      button: 'מערכת תיחקור סימולציות',
      config: {
        fill: 'השלם לְ- 120 שאלות (סימולציה מלאה)',
        marked: 'שאלות שסומנו על-ידִי בדגל',
        submit_each_question: 'הצג כתרגול (מצב שאלה-תשובה)',
        wrong: 'שאלות ששגיתי בהן',
      },
      confirm: {
        delete: {
          cancel: 'ביטול',
          confirm: 'כן, מחק',
          text: 'האם את\\ה בטוח\\ה שתרצו למחוק סימולציה שמורה זו?',
          title: 'מחק עותק שמור',
        },
        delete_practice: {
          cancel: 'ביטול',
          confirm: 'כן',
          text: 'האם הינך בטוח\\ה כי ברצונך למחוק סימולציה זו?',
          title: 'מחיקת סימולציה',
        },
        unfinished: {
          cancel: 'ביטול',
          confirm: 'התחל מחדש',
          text: 'האם ברצונך להתחיל מענה חדש ולדרוס את העותק הקיים?',
          title: 'שימ\\י לב! קיים עותק במענה חלקי עבור סימולציה זו',
        },
      },
      create_practice: 'יצירת סימולציה חדשה',
      denied: {
        button: 'קח אותי למרכז הפעולות שלי',
        text: 'מצטערים אך אין לך גישה לסימולציות אלו, במידה ודעותינו חלוקות בנושא אנא פנו אל צוות ALMOGMETHOD ונשמח לעזור!',
        title: 'הגישה נדחתה',
      },
      difficulties: { diff_1: 'קל', diff_2: 'נורמלי', diff_3: 'קשה', diff_4: 'קשה מאוד' },
      favorite: { mark: 'סמן סימולציה כמועדפת', unmark: 'הסר סימון סימולציה כמועדפת' },
      heading: 'הסימולציות שלי',
      info: {
        avg: 'ממוצע הציונים שלי:',
        delete_practice: 'מחיקת סימולציה',
        difficulty: 'רמת קושי:',
        first: 'הציון הראשון שלי:',
        history: 'היסטוריית סימולציה',
        last: 'הציון האחרון שלי:',
        marked: 'שאלות שסומנו על-ידִי:',
        max: 'הציון הגבוה ביותר שלי:',
        questions: 'מספר שאלות:',
        score: 'ניקוּד סימולציה (כביטוי קושי):',
        start: 'לנשום ולהירגע. עכשיו לחצו עליי להפעלת הסימולציה 🤓',
        wrong: 'שאלות ששגיתי בהן:',
      },
      saved: { answers: 'תשובות', minutes: 'דקות', questions: 'שאלות', title: 'נשמר אוטומטית במענה חלקי' },
      shuffle: {
        label: 'אפשרויות ערבוב שאלות ומסיחים',
        variant_0: 'בצע ערבוב רנדומלי',
        variant_1: 'תצוגה קבועה תצורה 1',
        variant_2: 'תצוגה קבועה תצורה 2',
        variant_3: 'תצוגה קבועה תצורה 3',
      },
      unfinished: {
        answers: 'תשובות:',
        continue: 'המשכ\\י',
        created_at: 'נוצר בתאריך:',
        elapsed: 'זמן שחלף:',
        minutes: 'דקות {{count}}',
        questions: 'שאלות:',
        tip: 'סימולציה במענה חלקי',
        title: 'נשמר אוטומטית במענה חלקי',
        updated_at: 'נשמר לאחרונה בתאריך:',
      },
    },
    simuresults: {
      correct: 'נענו נכון',
      empty: {
        button: 'קח אותי לסימולציות שלי',
        text: 'היסטוריה צריכה להיכתב על מנת להיקרא, ושלך עדיין לא נכתבה. אנא חיזרו לכאן שנית לאחר ביצוע סימולציה. ',
        title: 'אין היסטוריה להצגה',
      },
      heading: 'תחקורי סימולציה',
      minutes: 'דקות',
      questions: "מס' שאלות",
    },
    simustats: {
      chart: {
        average: 'ממוצע ציוניך',
        avg: 'ממוצע קורסיאלי',
        cols: {
          avg: 'ממוצע קורסיאלי',
          first: 'תוצאתך הראשונה ',
          reviews: '{{count}} פעמים',
          total: 'סה"כ ביצעת',
          uavg: 'ממוצע אישי',
        },
        desired: 'רמה רצויה (המלצת ALMOGMETHOD)',
        filters: {
          '1month': 'חודש',
          '1month_tip': 'מציג את הנתונים הרלוונטיים של יום שלם (משימה זֵהַה תשוקלל לממוצע), לתקופה של חודש אחורה',
          '1year': 'שנה אחת',
          '1year_tip': 'מציג את הנתונים הרלוונטיים של יום שלם (משימה זֵהַה תשוקלל לממוצע), לתקופה של שנה אחורה',
          '3months': '3 חודשים',
          '3months_tip': 'מציג את הנתונים הרלוונטיים של יום שלם (משימה זֵהַה תשוקלל לממוצע), לתקופה של 3 חודשים אחורה',
          all: 'מבראשית הבריאה',
          all_tip:
            'מציג את הנתונים הרלוונטיים מבראשית הבריאה, כאשר כל משימה מוצגת בנפרד כנקודה, גם אם נעשתה מספר פעמים ביום בודד',
        },
        result: 'ציונך',
        subtitle:
          'כאן מוצגת התקדמותך בכלל הסימולציות שבצעת, להצגת פילוח סימולציה או נושא ספציפיים אנא לחצ\\י עליהם בחלונות מטה',
        title: {
          results: 'סטטיסטיקה כללית',
          sessions: 'סטטיסטיקה כללית',
          simulations: 'מציג את פילוח נתוניך בסימולציות',
          topics: 'מציג את פילוח נתוניך בנושאי הבחינה',
        },
        total: 'סה"כ ביצעת',
        uavg: 'ממוצע אישי',
      },
      dialog: {
        cancel: 'הצג נתונים קורסיאליים',
        confirm: 'הסתר נתונים קורסיאליים',
        text: 'אנו מבינים כי לעיתים עדיף להימנע מהשוואות, ולהתרכז ביכולות האישיות שלנו. לכן יצרנו אפשרות להסתרת כלל נתוני שאר הסטודנטים, והצגת פילוח נתונים אישית בלבד!',
        title: 'הסתרת נתונים קורסיאליים',
      },
      heading: 'ניתוח נתוני הסימולציות שלי',
      legends: {
        avg: 'הממוצע שלך',
        better: 'הידד! הינך מעל הממוצע הקורסיאלי',
        same: 'הינך בממוצע הקורסיאלי',
        worst: 'הינך מתחת לממוצע הקורסיאלי',
      },
      table: {
        diff: 'מרחק',
        diff_tip: 'מרחקך מהממוצע הקורסיאלי (ירוק: מעל. אדום: מתחת)',
        more: 'הראה לי יותר...',
        percentage: 'ממוצע',
        percentage_tip: 'ממוצע ציוניך האישיים במשימה זו',
        sessions: 'כמות',
        sessions_tip: 'כמות הפעמים שמשימה זו בוצעה על-ידך',
        simulations: 'פילוח נתוני הסימולציות שביצעת',
        title: 'שם ',
        title_tip: 'שם הסימולציה \\ נושא',
        topics: 'פילוח נתוני ארבעת נושאי הבחינה',
      },
    },
    statistics: {
      buttons: {
        pressed: 'לחצת {{count}} פעמים',
        subtitle: 'כמות הפעמים שלחצת על כל אחד מכפתורי דירוג זכירת הכרטיסים',
        title: 'כפתורי דירוג ',
      },
      calendar: { title: 'לוח-שנה ' },
      cards: { title: 'ספירת כרטיסים' },
      count: {
        cards: '{{count}} כרטיסים',
        cardsDone: '{{done}}/{{due}} ({{percentage}}%) כרטיסים',
        cardsDue: '{{count}} כרטיסים לשינון',
        days: '{{count}} ימים',
        done: '{{count}} ({{percentage}}%) כרטיסים',
        minutes: '{{count}} דקות',
        minutesDay: '{{count}} דקות ליום',
        reviews: '{{count}} כרטיסים',
        reviewsDay: '{{count}} כרטיסים ליום',
        secondsCard: '{{count}} שניות / לכרטיס',
      },
      daily: {
        finished: { content: '{{count}} ימים', label: "מס' הימים שבהם השלמתי את השינון המתוזמן שלי" },
        must: { content: '{{count}} ימים', label: "מס' הימים שבהם קיים לי שינון " },
        studied: { content: '{{count}} ימים', label: "מס' הימים שבהם ביצעתי שינון " },
        title: 'עמידה ביעדי השינון היומי המתוזמן שלי',
        tooltip: {
          finished:
            'שינון הושלם 😁 : שיננתי {{done}} כרטיסים מתוך ה {{due}} שתוזמנו ({{percentage}}%). סה"כ {{reps}} צפיות',
          no_data: 'אין מידע על שינון ביום זה 🏖️',
          not_finished:
            'שינון חלקי 🥲 : שיננתי {{done}} כרטיסים מתוך ה {{due}} שתוזמנו ({{percentage}}%). סה"כ {{reps}} צפיות ',
          not_studied: 'לא ביצעתי שינון כלל 😞',
        },
      },
      decks: {
        currently: {
          site: 'אתמול ({{date}}) הממוצע הקורסיאלי היה <span>{{site}} חפיסות</span>',
          you_one: 'אתמול ({{date}}) היו ברשותי <span>{{you}} חפיסות</span> 😁',
          you_other: 'אתמול ({{date}}) היו ברשותי <span>{{you}} חפיסות</span> 😞',
        },
        dialog: {
          cancel: 'הצג',
          confirm: 'הסתר',
          text: 'אנו מבינים כי יש כאלו שיבחרו להימנע מהשוואות ולהתרכז בעשייה ומירוץ עצמי, ולכן ישנה האפשרות להסתיר את ההממוצע הקורסיאלי',
          title: 'הסתר ממוצע קורסיאלי',
        },
        site: "מס' החפיסות הממוצע לאדם בפרויקט",
        subtitle: 'מעקב אחר כמות חפיסות השינון שלי',
        title: 'חפיסות',
        user: "מס' החפיסות שלי",
      },
      done: {
        buried: 'כרטיסים "אחים" קבורים',
        done: 'שוננו',
        due: 'תוזמנו לשינון',
        subtitle: 'יעד שינון יומי',
        title: 'פירוט ביצועי יעדי השינון היומי המתוזמן שלי ',
      },
      due: {
        avg: 'ממוצע הכרטיסים שנלמדו ליום',
        subtitle: 'מספר הכרטיסים המתוזמנים לשינון בעתיד',
        title: 'כרטיסים מתוזמנים ',
        today: 'מתוזמנים לשינון היום',
        tomorrow: 'מתוזמנים לשינון מחר',
        total: 'סה"כ מתוזמנים',
      },
      ease: {
        avg: 'קַלוּת ממוצעת',
        ease: 'קַלוּת {{count}}',
        subtitle: 'ככל שקלות הכרטיס נמוכה יותר (קשה יותר לזכירה) כך גוברת התדירות שבה הוא יוצג לך',
        title: 'קַלוּת הכרטיס',
        total: 'סה"כ כרטיסים',
      },
      filters: {
        '1month': 'חודש אחד',
        '1year': 'שנה אחת',
        '3months': 'שלושה חודשים',
        all: 'מבראשית הבריאה',
        backlog: 'הצטברות',
        time: 'זמן למידה',
      },
      heading: 'הסטטיסטיקות שלי',
      hours: {
        correct: 'ידעתי הכל',
        range: '{{from}} ~ {{to}}',
        subtitle: 'פילוח של יכולת השינון שלך, כתלות בשעות השונות של היום. זכרו כי מבחן הידע מתבצע בשעות הבוקר.',
        title: 'פילוח שעתי',
        wrong: 'לא ידעתי',
      },
      intervals: {
        avg: 'מרווח הזמן הממוצע',
        subtitle: 'הזמן שחולף עד שכרטיס שנלמד יופיע לך שוב בעתיד ',
        title: 'מרווחי זמן ',
        total: 'סה"כ כרטיסים',
      },
      kind: {
        learning: 'כרטיסים בלימוד',
        mature: 'כרטיסים בוגרים',
        new: 'כרטיסים חדשים ',
        relearning: 'כרטיסים ללימוד חוזר ',
        review: 'כמות הכרטיסים שהוצגו',
        young: 'כרטיסים צעירים',
      },
      reviews: {
        avgDuration: 'זמן לימוד ממוצע לכרטיס',
        avgPeriod: 'ממוצע לאורך תקופת זמן',
        avgStudied: 'ממוצע למספר הימים שלמדת בהם',
        studied: 'מספר הימים שלמדת בהם',
        studiedInfo: '{{studiedDays}} מְ- {{allDays}} ({{percentage}}%)',
        subtitle: 'מספר הכרטיסים שהוצגו לך וענית עליהם',
        title: 'כרטיסים שהוצגו לך',
        tooltip: {
          reviews: '{{count}} כרטיסים. ממוצע של {{seconds}} שניות/לכרטיס',
          time: '{{count}} דקות. ממוצע של  {{seconds}} שניות/לכרטיס',
        },
        total: 'סה"כ',
      },
      tabs: { learn: 'ניתוח נתוני הלמידה שלי', simulations: 'ניתוח נתוני הסימולציות שלי' },
      today: {
        avg: 'ממוצע זמן לכרטיס',
        cardPerSec: '{{seconds}} שניות/כרטיס',
        cards: 'מספר הכרטיסים שהוצגו לי',
        duration: 'משך זמן הלמידה',
        reps: 'כמות החזרות הכללית שביצעתי',
        title: 'מה עשיתי היום:',
      },
    },
    training: {
      afterStudy: {
        button: 'הבנתי. אחזור לשנן 🤓',
        text: 'עלייך לסיים את השינון היומי שלך על-מנת להיות מסוגל לבצע שינון ידני נוסף',
        title: 'קיים לך שינון יומי מתוזמן',
      },
      disabled: {
        button: 'הבנתי',
        text: 'אל דאגה לא מדובר בתקלה השינון הידני כובה ע"י צוות הפרויקט ולכן איננו זמין כעת.',
        title: 'השינון הידני אינו זמין ',
      },
      done: {
        button: 'הבנתי',
        text: 'סיימת את השינון הידני שקבעת לעצמך, ניתן לאפס שינון זה \\ ליצור שינון חדש. שימו לב כי שינון ידני אינו נשמר במערכת בעת היציאה, וכן אינו מתוזמן מעבר ליום בודד.',
        title: 'ברכותינו, סיימת את השינון הידני שהצבת לעצמך!',
      },
      empty: {
        button: 'הוספת חפיסות שינון',
        text: 'לא נוספה חפיסת שינון עדיין',
        title: 'אנא הוסיפו חפיסה על-מנת לבצע שינון',
      },
      heading: 'שינון ידני',
      later: {
        button: 'קח אותי למרכז הפעולות שלי',
        text: 'נשארו לך כּ {{cards}} כרטיסים בשינון הידני שלך להיום, המתוזמנים החל מהשעה {{time}} ',
        title: 'חזור\\י מאוחר יותר על-מנת להמשיך לשנן! (יש לך 24 שעות לכך)',
      },
      start: {
        cards: 'כרטיסים',
        continue: 'המשך שינון ידני',
        deck: 'מאגר החפיסות שלך',
        due: 'לחזרה',
        new: 'חדשים',
        reset: 'אפס שינון נוכחי',
        start: 'התחל שינון ידני',
      },
    },
    userCards: {
      delayed: {
        edit: { label: 'תאריך חדש', submit: 'אישור', text: 'עריכת תזמון הצגת הכרטיס ', title: 'עריכת תזמון הכרטיס' },
      },
      empty: '[לצערנו לא נמצאו כרטיסים התואמים את חיפושך]',
      heading: 'הכרטיסים במאגר שלי',
      types: { comment: 'למידה שיתופית', delayed: 'תוזמנו ידנית', favorite: 'מועדפים', note: 'הערות אישיות' },
    },
    validation: {
      default: 'שדה אינו קיים',
      email: 'בקשה הכנס\\י כתובת מייל תקינה',
      long: 'שדה זה יכול להכיל לכל היותר ${max} תווים!',
      required: 'נדרש',
      short: 'שדה זה חייב להכיל לפחות ${min} תווים!',
    },
  },
};

export default he;
