const MIN_WAIT_MSECS = 10000;

const MAX_RETRIES = 5;

function defaultWaitDecide(millisecondsToWait, rateLimitRemaining, nthTry, response) {
  if (nthTry > MAX_RETRIES) return -1;

  return millisecondsToWait + MIN_WAIT_MSECS;
}

export async function rateLimitHandler(fetcher, waitDecide = defaultWaitDecide) {
  for (let i = 0; ; i++) {
    const response = await fetcher();

    switch (response.status) {
      default:
        return response;

      case 429:
        const rateLimitReset = parseInt(response.headers.get('x-ratelimit-reset'));

        let millisecondsToWait = isNaN(rateLimitReset) ? 0 : new Date(rateLimitReset * 1000).getTime() - Date.now();

        millisecondsToWait = waitDecide(
          millisecondsToWait,
          parseInt(response.headers.get('x-ratelimit-remaining')),
          i,
          response
        );
        if (millisecondsToWait <= 0) {
          return response;
        }
        await new Promise(resolve => setTimeout(resolve, millisecondsToWait));
    }
  }
}
