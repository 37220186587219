import { useState, useEffect } from 'react';
import { TOKEN_NAME, FORCE_TOKEN_NAME } from 'utils/constants';

const storage = window.localStorage;

const forceToken = storage.getItem(FORCE_TOKEN_NAME);

if (forceToken) {
  storage.removeItem(FORCE_TOKEN_NAME);
}

export { TOKEN_NAME };

export const hasToken = () => !!storage.getItem(TOKEN_NAME);

export const getToken = () => forceToken || storage.getItem(TOKEN_NAME);

export const setToken = token => storage.setItem(TOKEN_NAME, token);

export const removeToken = () => storage.removeItem(TOKEN_NAME);

export const useToken = () => {
  const [token, setToken] = useState(getToken);

  useEffect(() => {
    const listener = e => {
      if (e.key === TOKEN_NAME) {
        setToken(getToken());
      }
    };

    window.addEventListener('storage', listener);

    return () => window.removeEventListener('storage', listener);
  }, []);

  return token;
};

export const isImpersonating = () => !!forceToken;

export const doImpersonate = token => {
  storage.setItem(FORCE_TOKEN_NAME, token);
};

export const doneImpersonating = () => {
  storage.removeItem(FORCE_TOKEN_NAME);
};
